import ReactDOM from 'react-dom';
import React, {useState, forwardRef} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Message = props => {
    const {content, right, duration, type} = {...props};
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={duration || 3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: right ? 'right' : 'center'
            }}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={type}>{content}</Alert>
        </Snackbar>
    );
};

const message = {
    dom: null,
    success(content, right) {
        this.dom = document.createElement('div');
        const JSXDom = (<Message content={content} right={right} type="success" />);
        ReactDOM.render(JSXDom, this.dom);
        document.body.appendChild(this.dom);
    },
    error(content, right) {
        this.dom = document.createElement('div');
        const JSXDom = (<Message content={content} right={right} type="error" />);
        ReactDOM.render(JSXDom, this.dom);
        document.body.appendChild(this.dom);
    },
    warning(content, right) {
        this.dom = document.createElement('div');
        const JSXDom = (<Message content={content} right={right} type="warning" />);
        ReactDOM.render(JSXDom, this.dom);
        document.body.appendChild(this.dom);
    },
    info(content, right) {
        this.dom = document.createElement('div');
        const JSXDom = (<Message content={content} right={right} type="info" />);
        ReactDOM.render(JSXDom, this.dom);
        document.body.appendChild(this.dom);
    }
};

export default message;
