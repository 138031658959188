import {
    CHANGE_USERINFO,
    CHANGE_USERSETTING,
    CHANGE_PERMISSION_MENUS,
    CHANGE_PERMISSION_BUTTONS,
    CHANGE_CACHE_ROUTERS,
    CHANGE_NAV_SETTING, CHANGE_PREVIOUS_ROUTE
} from './action-types';

const initState = {
    userInfo: {},
    userSetting: {
        language: 'zh-CN',
        theme: 'dark'
    },
    // 权限菜单
    permissionMenus: [],
    // 权限按钮
    permissionButtons: {},
    // 路由缓存
    cacheRouters: [],
    // 语言
    userLang: 'zh-CN',
    navSetting: {
        sideShow: true,
        sideOpen: false
    },
    // 记录上一条路由
    previousRoute: null
};

const Reducer = (state = initState, action = {}) => {
    switch (action.type) {
        case CHANGE_USERINFO:
            return {...state, userInfo: action.data};
        case CHANGE_USERSETTING:
            return {
                ...state,
                userSetting: {
                    ...state.userSetting,
                    ...action.data || {}
                }
            };
        case CHANGE_PERMISSION_MENUS:
            return {...state, permissionMenus: action.data};
        case CHANGE_PERMISSION_BUTTONS:
            return {...state, permissionButtons: action.data};
        case CHANGE_CACHE_ROUTERS:
            return {...state, cacheRouters: action.data};
        case CHANGE_NAV_SETTING:
            return {...state, navSetting: action.data};
        case CHANGE_PREVIOUS_ROUTE:
            return {...state, previousRoute: action.data};
        default:
            return state;
    }
};
export default Reducer;
