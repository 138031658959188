import _ from 'lodash';
import {useSelector} from 'react-redux';

// Layout-navMenu
export function useMenuData() {
    const navList = [];
    const routerArray = useSelector(state => state.user.permissionMenus);
    const layoutRoute = _.find(_.cloneDeep(routerArray), item => item.name === 'layout');
    _.forEach(layoutRoute.children || [], item => {
        if (item.meta && item.meta.type === '菜单') {
            navList.push(item);
        }
        else if (item.meta && item.meta.type === '目录') {
            const submenu = [];
            for (const secItem of item.children) {
                if (secItem.meta && secItem.meta.type === '菜单') {
                    submenu.push(secItem);
                }
                else if (secItem.meta && secItem.meta.type === '目录') {
                    const thirdChild = _.filter(secItem.children, i => i.meta && i.meta.type === '菜单');
                    submenu.push({
                        ...secItem,
                        children: thirdChild
                    });
                }
            }

            navList.push({
                ...item,
                children: submenu
            });
        }
    });
    return navList;
}
