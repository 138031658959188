import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/styles';
import Typography from '@mui/material/Typography';
import SvgIcon from '@/components/SvgIcon';

const AppTitle = ({variant = 'subtitle1', logoWidth = 35}) => {
    const theme = useTheme();
    return (
        <Typography className="flex-start" variant={variant} component="div" sx={{fontWeight: 600, color: 'nav.color'}}>
            <SvgIcon
                icon="logo"
                size={logoWidth}
                color={'#D20101'}
                style={{marginRight: theme.spacing(2)}}
            />
            Vehicle Threat Intelligence
        </Typography>
    );
};

AppTitle.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    variant: PropTypes.string,
    logoWidth: PropTypes.number
};
export default AppTitle;
