export function getStorage(key) {
    if (localStorage) {
        return localStorage[key];
    }
    return '';
}

export function setStorage(key, value) {
    localStorage.setItem(key, value);
}

export function removeStorage(key) {
    localStorage.removeItem(key);
}

/**
 * Storage-token
 * @returns {*|string}
 */

export function getToken() {
    return getStorage('access_token');
}

export function setToken(value) {
    return setStorage('access_token', value);
}

export function clearToken() {
    return removeStorage('access_token');
}

/**
 * Storage-ExpireTime
 * @returns {*|string}
 */

export function getExpireTime() {
    return getStorage('expired_timestamp');
}

export function setExpireTime(value) {
    return setStorage('expired_timestamp', value);
}

export function clearExpireTime() {
    return removeStorage('expired_timestamp');
}

/**
 * Storage-DBCurrentRoute
 * @returns {*|string}
 */

export function getDBCurrentRoute() {
    return getStorage('db_current_route');
}

export function setDBCurrentRoute(value) {
    return setStorage('db_current_route', value);
}

export function clearDBCurrentRoute() {
    return removeStorage('db_current_route');
}

/**
 * Storage-clearAll
 * @returns {*|string}
 */
export function clearAll() {
    clearDBCurrentRoute();
    clearExpireTime();
    clearToken();
    sessionStorage.clear();
}
