import React from 'react';
import _ from 'lodash';
import {Navigate} from 'react-router-dom';
import {LayoutIndex} from '@/router/utils/constant';
import LazyLoad from '@/router/utils/lazyLoad';
import {handleTree} from '@/utils/common';

const menuDeepRoute = o => {
    // 菜单下的菜单-用于存储详情页面路由eg:事件详情
    const childMenu = _.filter(o.children || [], p => p.type === '菜单');
    const childMenuRoute = _.map(childMenu, c => ({
        path: c.url,
        element: <LazyLoad route={c.component} />,
        meta: {title: c.name, usTitle: c.usName || c.name, hidden: !!c.hidden, cache: !!c.cache, link: !!c.link},
        id: `${c.permissionCode}${c.id}`
    }));

    return [
        {
            path: o.url,
            element: <LazyLoad route={o.component} />,
            meta: {
                title: o.name,
                usTitle: o.usName || o.name,
                icon: o.icon,
                type: o.type,
                hidden: !!o.hidden,
                cache: !!o.cache,
                link: !!o.link
            },
            id: `${o.permissionCode}${o.id}`
        },
        ...childMenuRoute
    ];
};

const menuRoute = (menu = []) => {
    const permissions = _.orderBy(menu, ['sortNum'], ['asc']);
    const treeMenu = handleTree({data: permissions});

    const routerArray = [];
    const layoutChild = [];
    for (const item of treeMenu) {
        if (item.permissionCode === 'situation') {
            const situationRoute = _.map(item.children || [], i => ({
                path: i.url,
                element: <LazyLoad route={i.component} />,
                meta: {
                    title: i.name,
                    usTitle: i.usName || i.name,
                    hidden: !!i.hidden,
                    cache: !!i.cache,
                    link: !!i.link
                },
                id: `${i.permissionCode}${i.id}`,
                name: 'situation'
            }));
            routerArray.push(...situationRoute);
        }
        else if (item.type === '菜单') {
            layoutChild.push(...menuDeepRoute(item));
        }
        else if (item.type === '目录') {

            //   二级菜单处理
            let secondNav = [];
            for (const secChild of item?.children || []) {
                if (secChild.type === '菜单') {
                    secondNav = [
                        ...secondNav,
                        ...menuDeepRoute(secChild)
                    ];
                }
                else if (secChild.type === '目录') {
                    //  三级菜单处理
                    const thirdChildRoute = _.flattenDepth(_.map(secChild.children, child => menuDeepRoute(child)), 1);
                    secondNav.push({
                        id: `${secChild.permissionCode}${secChild.id}`,
                        path: secChild.url,
                        meta: {
                            title: secChild.name,
                            usTitle: secChild.usName || secChild.name,
                            icon: secChild.icon,
                            type: '目录',
                            hidden: !!secChild.hidden,
                            cache: !!secChild.cache,
                            link: !!secChild.link
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to={thirdChildRoute[0].path} />
                            },
                            ...thirdChildRoute
                        ]
                    });
                }
            }

            layoutChild.push({
                id: `${item.permissionCode}${item.id}`,
                path: item.url,
                meta: {
                    title: item.name,
                    usTitle: item.usName || item.name,
                    icon: item.icon,
                    type: '目录',
                    hidden: !!item.hidden,
                    cache: !!item.cache,
                    link: !!item.link
                },
                children: secondNav.length ? [
                    {
                        index: true,
                        element: <Navigate to={secondNav[0].path} />
                    },
                    ...secondNav
                ] : []
            });
        }
    }
    routerArray.push({
        name: 'layout',
        path: '/',
        element: <LayoutIndex />,
        children: layoutChild.length ? [
            {index: true, element: <Navigate to={layoutChild[0].path} />},
            ...layoutChild
        ] : []
    });

    return routerArray;
};

export default menuRoute;
