import request from '../utils/request';

/**
 * 用户信息
 */
export default {
    getUserInfo() {
        return request({
            url: '/sys/user/info',
            method: 'get'
        });
    },
    getUserSetting(data) {
        return request({
            url: '/sys/user/userProperties',
            method: 'post',
            type: 'json',
            data: data
        });
    },
    updateUserPassword(data) {
        return request({
            url: '/sys/user/update/password',
            method: 'post',
            data: data
        });
    }
};
