/* eslint-disable max-len */
const en_US = {
    'menu.cve': 'Vehicle Threat Intelligence Platform',
    'user.theme': 'Switch Theme',
    'user.exit': 'Log Out',

    // dashboard
    '概览': 'Dashboard',
    '漏洞总数': 'Total Vulnerability',
    '漏洞严重程度': 'Vulnerability Severity',
    'OTA升级': 'OTA Upgrade',
    '智能诊断': 'Intelligent Diagnosis',
    '智能座舱': 'Intelligent Cockpit',
    '智能驾驶': 'Intelligent Driving',
    '智能控车': 'Intelligent Car Control',
    '智能充电': 'Intelligent Charging',
    '其它': 'Other',
    '本周推送事件情报数': 'Number of push events this week',
    '本周推送漏洞情报数': 'Number of vulnerabilities pushed this week',
    '推送事件情报总数': 'Total number of push event messages',
    '推送漏洞情报总数': 'Total number of vulnerabilities pushed',
    '最新事件情报': 'Latest Incident Information',
    '供应链漏洞厂商分布': 'Supplier Distribution of Supply Chain Vulnerability',
    'WP.29 CSMS漏洞合规状态': 'WP.29 CSMS Vulnerability Compliance Status',
    '4.3.1 有关后端服务器的威胁': '4.3.1 Threats Related to back-end Servers',
    '4.3.2 有关通信信道威胁': '4.3.2 About Communication Channel Threats',
    '4.3.3 有关软件程序更新威胁': '4.3.3 About Software Program Update Threats',
    '4.3.4 无意识人类行为对车辆的威胁': '4.3.4 Threat to Vehicles from Unconscious Human Actions',
    '4.3.5 外部连接及通信威胁': '4.3.5 External Connection and Communication Threats',
    '4.3.6 对车辆数据/代码的威胁': '4.3.6 Threats to Vehicle Data/Codes',
    '4.3.7 潜在可利用漏洞': '4.3.7 Potentially Exploitable Vulnerabilities',

    '供应链漏洞ECU分布': 'ECU Distribution of Supply Chain Vulnerabilities',
    '高频供应链漏洞TOP10': 'TOP10 supply chain vulnerabilities',

    // 系统角色
    '配置': 'Configuration',
    '系统角色': 'User Role',
    '角色名称': 'Role Name',
    '菜单权限': 'Menu Permissions',
    '描述': 'Description',
    '展开/折叠': 'Expand/Collapse',
    '全选/全不选': 'Select All/Unselect All',
    '父子联动': 'Expand All',
    '可查询': 'View',
    '可编辑': 'Edit',
    '规则/策略 启用权限': 'Rule/Policy Enable Permission',
    // 系统用户
    '系统用户': 'User',
    '登录用户名': 'Username',
    '姓名': 'Name',
    '手机号': 'Phone Number',
    '邮箱': 'Email',
    '修改密码': 'Change Password',
    '角色': 'Role',
    '资产域': 'Asset Domain',
    '密码': 'Password',
    '确认密码': 'Confirm Password',
    '修改邮箱': 'Modify Email',
    // 数据字典配置
    '数据字典配置': 'Data Dictionary Config',
    '字典标签': 'Tag',
    '字典键值': 'Value',
    '字典排序': 'Order',
    '英文标签': 'Label',
    '数据类型': 'Data Type',
    '枚举值': 'Enumeration Value',
    '单位': 'Unit',
    '边界值': 'Boundary Value',
    '数据分类': 'Data Classification',
    '数据分级': 'Data Grading',
    '备注': 'Remark',
    '数据字典': 'Data Dictionary',
    '枚举键值': 'Enum value',
    '枚举标签': 'Enum Label',
    '算法检测方法': 'Algorithmic Detection Method',
    '规则检测方法': 'Rule-Based Detection Method',
    'R155威胁描述': 'Threat Description for R155',
    '检测对象': 'Detection Target',
    '云端服务类型': 'Cloud Service Type',
    '检测数据中是否包含指定的字符串': 'Check if the detection data contains the specified string',
    '检测一定时间窗口内，平均值是否大于设定的阈值': 'Check if the average value within a certain time window is greater than the set threshold',
    '检测一定时间窗口内，值大于设定阈值的次数，是否超出设定的次数阈值': 'Check if the number of times the value is greater than the set threshold within a certain time window exceeds the set threshold',
    '检测一定时间窗口内，值等于指定值的次数，是否超出设定的次数阈值': 'Check if the number of times the value equals the specified value within a certain time window exceeds the set threshold',
    '检测一定时间窗口内，数据值变化次数是否超过设定的次数阈值': 'Check if the number of data value changes within a certain time window exceeds the set threshold',
    '指被检测项在一定时间窗口内，最大和最小值之间的差值，是否超过了设定的阈值。': 'Check if the difference between the maximum and minimum values of the detected item within a certain time window exceeds the set threshold',

    // 数据源配置
    '数据源配置': 'Data Source Configuration',
    '更新时间': 'Update Time',
    '添加数据源': 'Add Data Source',
    '是否确认重置数据源?': 'Confirm Reset Data Source?',
    '确认暂停数据源?': 'Confirm Pausing Data Source?',
    '确认启动数据源?': 'Confirm start data source?',
    '字段映射列表': 'Field Mapping List',
    '基础信息查看': 'View Basic Information',
    '基础信息编辑': 'Basic Information Editing',
    '重置': 'Reset',
    '暂停': 'Pause',
    '启动': 'Launch',
    '连接': 'Connect',
    '连接测试处理中...': 'Connection Test Processing...',
    'Groovy脚本': 'Groovy Script',
    '数据源名称': 'Data Source Name',
    '数据源描述': 'Data Source Description',
    '数据协议': 'Data Protocol',
    '连接参数': 'Connection Parameters',
    '数据内容格式': 'Data Content Format',
    '服务地址': 'Service Address',
    '消费组名称': 'Consumer Group Name',
    '自定义参数': 'Custom Parameters',
    '新建自定义参数': 'Add Custom Parameters',
    '删除自定义参数': 'Delete Custom Parameters',
    '文件类型': 'File Type',
    '文件路径': 'File Path',
    '头信息': 'Header Information',
    'CSV 头信息': 'CSV Deader Information',
    '文件夹路径': 'Folder Path',
    '子目录': 'Subdirectory',
    '文件名后缀': 'Filename Suffix',
    '服务器地址': 'Server Address',
    '用户名': 'Username',
    '消息主题': 'Topic',
    'CA根证书': 'CA Root Certificate',
    '客户端证书': 'client Certificate',
    '证书key': 'Certificate Key',
    '消息解析类': 'Message Parsing Class',
    'Protobuf描述文件': 'Protobuf Desc File',
    '字段名': 'Field',
    '值': 'Value',
    '字段映射': 'Field Mapping',
    '批量新建字段映射': 'Batch New Field Mapping',
    '文件格式': 'File Format',
    '选择文档': 'Select Document',
    '选择图片': 'Select Picture',
    '文件种类': 'File Type',
    '文件模板': 'File Template',
    '好的': 'OK',
    '模板下载': 'Template Download',
    '请选择文档': 'Please Select a Document',
    '请选择有效的CSV文件': 'Please Select a Valid CSV File.',
    'CSV模板下载': 'CSV Template Download',
    '字段映射信息列表': 'List of Field Mapping Information',
    '源字段名称': 'Source Field Name',
    'S3标准字段名称': 'S3 Standard Field Names',
    '新建S3标准字段名称': 'Add S3 Standard Field Names',
    '删除S3标准字段名称': 'Delete S3 Standard Field Names',
    '中文名称': 'Chinese Name',
    '数据格式': 'Data Format',
    '输出数据格式': 'Output Data Format',
    'JSON路径': 'JSON Path',
    '数据偏移': 'Data Offset',
    'Kafka服务器地址和端口，多个用逗号分割, eg: 192.168.1.2:9092,192.168.1.3:9092':
        'Kafka Server addresses and ports, separated by commas, e.g., 192.168.1.2:9092,192.168.1.3:9092',
    'Kafka数据主题': 'Kafka Data Topic',
    '由英文字母、数字、横杠、下划线组成': 'Consisting of English letters, numbers, hyphens, and underscores',
    '主题(topic)': 'Topic',
    '服务器地址，如tcp://localhost:18883': 'Server address, e.g., tcp://localhost:18883',
    '认证用户名': 'Authentication Username',
    '认证密码': 'Authentication Password',
    '支持通配符，如：/vsoc/#': 'Supports wildcard, e.g., /vsoc/#',
    'CA根证书内容，支持换行，通常以 -----BEGIN CERTIFICATE----- 开头，以 -----END CERTIFICATE----- 结尾':
        'CA root certificate content, supports line breaks, usually starts with -----BEGIN '
        + 'CERTIFICATE----- and ends with -----END CERTIFICATE-----',
    '客户端证书，支持换行，通常以 -----BEGIN CERTIFICATE----- 开头，以 -----END CERTIFICATE----- 结尾':
        'Client certificate, supports line breaks, usually starts with -----BEGIN '
        + 'CERTIFICATE----- and ends with -----END CERTIFICATE-----',
    '证书key，支持换行，通常以 -----BEGIN RSA PRIVATE KEY----- 开头，以 -----END RSA PRIVATE KEY----- 结尾':
        'Certificate key, supports line breaks, usually starts with -----BEGIN '
        + 'RSA PRIVATE KEY----- and ends with -----END RSA PRIVATE KEY-----',
    '消息解析类，如：com.callisto.adapter.proto.decoder.IdpsDecoder':
        'Message parsing class, e.g., com.callisto.adapter.proto.decoder.IdpsDecoder',
    '描述文件内容，二进制文件进行base64编码后的字符串': 'Description file content, base64 encoded string of binary file',
    '文件源类型': 'File Source Type',
    '服务器本地文件': 'Server Local File',
    'minio服务器文件': 'Minio Server File',
    '输入文件路径，minio文件输入bucket(不含)后到路径部分': 'Input file path, for minio file, input the path after the bucket (excluding)',
    '文件中是否包含头信息': 'Whether the file contains header information',
    '文件中包含头信息': 'File contains header information',
    '文件中无头信息': 'File has no header information',
    'csv 头信息': 'CSV Header Information',
    '多个字段名按顺序填写，用逗号,分割': 'Multiple field names filled in order, separated by commas',
    '访问密钥': 'Access Key',
    '是否遍历子目录': 'Whether to traverse subdirectories',
    '遍历子目录': 'Traverse Subdirectories',
    '仅当前目录': 'Only Current Directory',
    '访问密码': 'Access Password',
    '服务器地址，如test.myhuaweicloud.com': 'Server address, e.g., test.myhuaweicloud.com',
    '消息队列名称': 'Message Queue Name',
    '消息队列名称，如test-message': 'Message Queue Name, e.g., test-message',
    '默认为 csv, 如不限后缀名请留空': 'Default is csv, leave blank if no file extension is limited',
    '脚本示例：': 'Script Example:',
    '(系统已内置 fastjson库，可直接import使用)': '(The system has built-in fastjson library, can be directly imported and used)',
    '须实现接口：': 'Must implement interface:',
    '把接入的原始消息数据字符串转换成': 'Convert the input raw message data string to',
    'Groovy语言脚本，长文本，支持换行符，可选项': 'Groovy language script, long text, supports line breaks, optional',

    // 系统配置
    '安全设置': 'Security Settings',
    '系统设置': 'System Settings',
    '收发信设置': 'Send and Receive Settings',
    '邮件服务器设置': 'Mail Server Settings',
    '发件服务器主机': 'Outgoing Server Host',
    '发件服务器端口': 'SMTP Server Port',
    '是否需要认证': 'Is Authentication Required',
    '认证账户地址': 'Authentication Account Address',
    '认证账户密码': 'Authentication Account Password',
    '发送邮箱账户地址': 'Sender Email Account Address',
    '发送账户名称': 'Sender Account Name',
    '是否使用网络代理': 'Whether to Use a Network Proxy',
    '代理服务器地址': 'Proxy Server Address',
    '代理服务器端口': 'Proxy Server Port',
    '代理用户名': 'Proxy Username',
    '代理用户密码': 'Proxy User Password',
    '短信网关设置': 'SMS Gateway Settings',
    '云服务提供商': 'Cloud Service Provider',
    '接口APPID': 'Interface APPID',
    '接口密钥': 'Interface Secret Key',
    '用户安全登录设置': 'User Security Login Settings',
    '最小密码长度': 'Minimum Password Length',
    '密码必须包含的字符': 'Characters Required in the Password',
    '特殊字符': 'Special Characters',
    '大写字母': 'Uppercase Letters',
    '小写字母': 'Lowercase Letters',
    '数字': 'Numbers',
    '登录失败锁定是否开启': 'Whether the Login Failure Lock is Enabled',
    '登录失败N次将锁定': 'Login Failed N Times Will be Locked',
    '登录失败锁定时长': 'Login Failure Lockout Duration',
    '双因子登录认证': 'Two-Factor Authentication',
    '邮件令牌（邮件动态码）': 'Postal Code',
    '分钟': 'Minute(s)',
    '位': 'Digits',

    // 漏洞/情报数据源更新配置
    '漏洞数据源更新配置': 'Vulnerability Data Source Update Configuration',
    '文件导入': 'File Import',
    'API接口订阅': 'API Interface Subscription',
    '定时更新': 'Periodic Update',
    '实时更新': 'Real-time Update',
    '提示: 通过API接口, 定期获取公开漏洞漏洞数据': 'Tip: Periodically obtain public vulnerability data through the API interface',
    '情报数据源更新配置': 'Intelligence Data Source Update Configuration',
    '汽车攻防案例分析': 'Automobile Attack and Defense Case Analysis',
    '提示: 分析更新功能开启后, 木卫四将自动推送威胁情报到用户': 'Tip: When the analysis update function is turned on, Callisto will automatically push threat intelligence to the user',
    '提示: 通过API接口, 定期获取公开威胁情报数据': 'Tip: After the vehicle attack and defense case analysis update function is turned on, Callisto will automatically push threat intelligence to the user',
    '深网/暗网威胁情报': 'Deep Web/Dark Web Threat Intelligence',
    '提示: 通过API接口, 定期获取深网/暗网威胁情报数据': 'Tip: Obtain deep web/dark Web threat intelligence data regularly through the API interface',

    // 报告
    '报告': 'Report',
    '报告管理': 'Report Management',
    '报告汇总': 'Report Summary',
    '报告任务': 'Report Task',
    '报告模版': 'Report Template',
    '报告模版名称': 'Report Template Name',
    '创建人': 'Creator',
    '报告样例': 'Report Sample',
    '展示报告样例': 'Show Sample Report',
    '报告名称': 'Report Name',
    '生成时间': 'Build Time',
    '运行时间': 'Operation Hours',
    '上次': 'Last Time',
    '下次': 'Next Time',
    '起/停': 'Start/Stop',
    '生成频率': 'Generation Frequency',
    '一次性': 'Disposable',
    '重复性': 'Repeatability',
    '立刻生成': 'Generate Immediately',
    '定时生成': 'Timed Generation',
    '重复频率': 'Repeat Frequency',
    '每天': 'Everyday',
    '每周': 'Weekly',
    '每月': 'Monthly',
    '日期': 'Date',
    '时间': 'Time',
    '报告格式': 'Report Format',
    '发送方式': 'Sending Method',
    '任务状态': 'Task Status',
    '报告类型': 'Report Type',
    '日报': 'Daily Report',
    '周报': 'Weekly Report',
    '月报': 'Monthly Report',
    '季报': 'Quarterly Report',
    '年报': 'Annual Report',
    '周数': 'Weeks',
    '月份': 'Months',
    '季度': 'Quarters',
    '第一季度': 'First Quarter',
    '第二季度': 'Second Quarter',
    '第三季度': 'Third Quarter',
    '第四季度': 'Fourth Quarter',
    '邮件': 'Mail',
    '微信': 'WeChat',
    '邮箱地址': 'Email Address',
    '绑定成功': 'Bind Successfully',
    '请先扫码关注木卫四服务号': 'Please scan the QR code to follow Callisto WeChat Official Accounts',
    '重新绑定': 'Rebind',
    '扫码绑定': 'Scan Code Binding',
    '生成报告': 'Generate Report',
    '模板配置': 'Template Configuration',
    '任务配置': 'Task Configuration',
    '报告模板': 'Report Template',
    '选择车型': 'Select Vehicle Model',
    '报告生成内容': 'Report Generation Content',
    '选择ECU': 'Select ECU',
    '选择车辆': 'Select Vehicle',
    '车辆概述': 'Vehicle Overview',
    '车辆EE架构': 'Vehicle EE Architecture',
    '车辆安全态势': 'Vehicle Security Situation',
    '车辆异常事件分析': 'Vehicle Abnormal Event Analysis',
    '本车的TOP5异常': 'Top 5 Abnormalities of This Vehicle',
    '车辆供应链漏洞分析': 'Vehicle Supply Chain Vulnerability Analysis',
    '供应链漏洞危害等级分布': 'Distribution of Supply Chain Vulnerability Harm Levels',
    '功能场景漏洞分布': 'Distribution of Functional Scenario Vulnerabilities',
    'WP.29 R155 合规漏洞管理状况': 'WP.29 R155 Compliance Vulnerability Management Status',
    '供应链厂家分布': 'Distribution of Supply Chain Manufacturers',
    '控制器种类分布': 'Distribution of Controller Types',
    'TOP10漏洞': 'Top 10 Vulnerabilities',
    '报告总结': 'Report Summary',
    '车型概述': 'Vehicle Model Overview',
    '车型EE架构': 'Vehicle Model EE Architecture',
    '车型安全态势': 'Vehicle Model Security Situation',
    '车型安全态势持续监控': 'Continuous Monitoring of Vehicle Safety Situation',
    '车型异常事件分析': 'Vehicle Model Abnormal Event Analysis',
    '车型异常持续监控': 'Continuous Monitoring of Car Model Anomalies',
    '本车型的 TOP5 异常': 'Top 5 Abnormalities of This Vehicle Model',
    '车型供应链漏洞分析': 'Vehicle Model Supply Chain Vulnerability Analysis',
    '车型供应链漏洞持续监控': 'Continuous Monitoring of Vehicle Model Supply Chain Vulnerabilities',
    '车型合规状态': 'Vehicle Model Compliance Status',
    'UNECE WP.29 R155 合规': 'UNECE WP.29 R155 Compliance',
    'R155 威胁场景检测覆盖率分析': 'R155 Threat Scenario Detection Coverage Analysis',
    'R155 威胁检测清单 差距分析详情': 'R155 Threat Detection List Gap Analysis Details',
    'GB-XX《汽车整车信息安全技术要求》差距分析': 'GB-XX Gap Analysis of "Automobile Integrated Information Security Technical '
        + 'Requirements"',
    '国标安全要求 差距分析详情': 'National Security Requirements Gap Analysis Details',
    '国标安全要求测试验证方法 差距分析详情': 'National Security Requirements Test Verification Method Gap Analysis Details',
    'ECU概述': 'ECU Overview',
    'ECU基础信息': 'ECU Basic Information',
    'ECU安全态势': 'ECU Security Situation',
    'ECU异常事件分析': 'ECU Abnormal Event Analysis',
    'ECU供应链漏洞分析': 'ECU Supply Chain Vulnerability Analysis',
    '报告综述': 'Report Overview',
    '平台建设背景和意义': 'Background and Significance of Platform Construction',
    '综述信息': 'Overview Information',
    '受保护车辆资产': 'Protected Vehicle Assets',
    '安全风险检测策略及措施': 'Security Risk Detection Policy and Measures',
    '数据采集': 'Data Collection',
    '数据处理速率': 'Data Processing Rate',
    '事件应急响应': 'Incident Emergency Response',
    '漏洞&威胁情报预警': 'Vulnerability & Threat Intelligence Early Warning',
    'TOP10 漏洞': 'Top 10 Vulnerabilities',
    '平台运行监控': 'Platform Operation Monitoring',
    '系统同时在线人数统计': 'Simultaneous Online User Count Statistics',
    '车型安全报告': 'Vehicle Model Security Report',
    'ECU安全报告': 'ECU Security Report',
    '零部件wp.29漏洞合规报告': 'Component WP.29 Vulnerability Compliance Report',
    'VSOC平台运作报告': 'VSOC Platform Operation Report',
    '车辆安全报告': 'Vehicle Security Report',
    '安全应急响应': 'Security Emergency Response',
    '异常统计': 'Abnormal Statistics',
    '告警统计': 'Alarm Statistics',
    '安全风险缓解情况': 'Security Risk Mitigation Status',
    'WP.29合规报告': 'WP.29 Compliance Report',
    'ECU合规分析': 'ECU Compliance Analysis',
    'UNECE WP.29 R155合规覆盖率': 'UNECE WP.29 R155 Compliance Coverage Rate',
    '供应链漏洞R155合规分析': 'Supply Chain Vulnerability R155 Compliance Analysis',
    'R155漏洞检测清单 差距分析详情': 'R155 Vulnerability Detection Checklist Gap Analysis Details',


    // 安全工单
    '安全工单': 'Security Work Order',
    '处理人': 'Handler',
    '创建时间': 'Creation Time',
    '处理时长': 'Processing Time',
    '已超时': 'Timed Out',
    '未处理': 'Unprocessed',
    '处理中': 'Processing',
    '已处理': 'Processed',
    '解决方案': 'Solution',
    '处理方法': 'Approach',
    '处理记录': 'Processing Records',
    '处理完毕': 'Processed',
    '限时完成': 'Limited Time',
    '创建工单': 'Create Work Order',
    '查看工单': 'View Work Order',
    '修改防火墙策略': 'Modify Firewall Policy',
    '以下防火墙策略是经过分析后，生成的防火墙策略。如需修改，点击修改防火墙策略按钮':
        'The ollowing firewall policies are generated after analysis.'
        + ' To modify, click the Modify Firewall Policy button',
    '下发': 'Issued',
    '离线处理': 'Offline Processing',
    '立刻在线下发防火强阻断策略': 'Immediately release the strong blocking policy of fire protection online',
    '周日': 'Sunday',
    '周一': 'Monday',
    '周二': 'Tuesday',
    '周三': 'Wednesday',
    '周四': 'Thursday',
    '周五': 'Friday',
    '周六': 'Saturday',
    '风险研判': 'Risk Judgment',
    '风险处置审批': 'Risk Approval',
    '风险处理': 'Risk Management',
    '流程结束': 'End of Process',
    '我的待办': 'To-Do List',
    '我的已办': 'Completed Tasks',
    '我发起的工单': 'Tickets Initiated by Me',
    '超时工单': 'Overtime Work Order',
    '处理流程': 'Processing Flow',
    '审批结果': 'Approval Results',
    '研判结果': 'Analysis Results',
    '处理结果 ': 'Process Results',
    '审批意见': 'Approval Comments',
    '通过': 'Pass',
    '不通过': 'Fail',
    '处理完成': 'Finish',
    '工单流程管理': 'Work Order Process Mgt',
    '异常事件处理流程': 'Abnormal Event Handling Process',
    '所有用户': 'All Users',
    '指定角色': 'Designated Role',
    '修改': 'Edit',
    '版本': 'Version',
    '供应链漏洞处理流程': 'Supply Chain Vulnerability Handling Process',
    '漏洞识别与分类': 'Vulnerability Identification and Classification',
    '漏洞评估和风险分析': 'Vulnerability Assessment and Risk Analysis',
    '漏洞修复和补丁管理': 'Vulnerability Remediation and Patch Management',
    '漏洞修复验证': 'Vulnerability Remediation Verification',
    '漏洞修复验证确认': 'Vulnerability Remediation Verification Confirmation',
    '关闭工单': 'Close Work Order',
    '忽略': 'Ignore',
    '标记为误报': 'Mark as False Positive',
    '误报原因': 'Cause of False Alarm',
    '误报管理': 'False Positive Management',
    '误报处理中...': 'False Positive Processing...',
    '工单创建中...': 'Work Order Creation in Progress...',
    '下发中...': 'Issuing in Progress...',
    '处理中...': 'Processing...',
    '工单处理成功': 'Work Order Successfully Processed',
    '经过综合分析，此异常是误报，具体原因如下:': 'After comprehensive analysis, this anomaly is a false positive, '
        + 'and the specific reasons are as follows:',
    '标记人': 'Marker',
    '缓解措施': 'Mitigation Measures',

    // ECU管理
    'ECU管理': 'ECU Management',
    'ECU搜索': 'ECU search',
    '型号': 'Model Number',
    '基本信息': 'Basic Information',
    '漏洞概览': 'Vulnerability Overview',
    'ECU名称': 'ECU Name',
    'ECU种类': 'ECU Type',
    '供应商': 'Supplier',
    '供应商代码': 'Supplier Code',
    '零件号': 'Part Number',
    '软件版本': 'Software Version',
    '硬件版本': 'Hardware Version',
    '最新版本': 'Latest Version',
    '出厂日期': 'Factory Date',
    'ECU所属车型': 'Model of the ECU',
    '操作系统': 'Operating System',
    '基础信息': 'Basic Information',
    '软件信息': 'Software Information',
    '版本号': 'Version Number',
    '软件漏洞': 'Software Vulnerability',
    '漏洞离线分析结果上传': 'Vulnerability offline analysis results uploaded',
    '批量导入': 'Batch Import',
    'SBOM在线分析': 'Online analysis of SBOM',
    '上传': 'Upload',
    '上传中': 'Up Cross',
    '上传成功': 'Upload Successfully',
    '选择文件': 'Select File',
    'SBOM信息': 'SBOM Information',
    '未上传SBOM在线分析文件': 'No online SBOM analysis file has been uploaded Procedure',
    '解析失败': 'Parsing Failure',
    '重新解析SBOM信息': 'Parse the SBOM Information Again',
    'SBOM信息解析中, 请稍后': 'SBOM information parsing, please wait',
    '重新在线分析漏洞信息': 'Re-analyze Vulnerability Information Online',
    '分析完成': 'Analysis Completion',

    // 漏洞管理
    '漏洞列表': 'Vulnerability List',
    '漏洞数量': 'Number of Vulnerabilities',
    '漏洞': 'Vulnerability',
    '向量': 'Vector',
    '向量3': 'Vector3',
    '木卫四分数': 'Callisto Score',
    '修复状态': 'Fix Status',
    '供应链漏洞': 'Supply Chain Vulnerabilities',
    '漏洞ID': 'Vulnerability ID',
    '危害等级': 'Severity Level',
    '严重程度': 'Severity Level',
    '低危': 'LOW',
    '中危': 'MEDIUM',
    '高危': 'HIGH',
    '超危': 'CRITICAL',
    '待定级': 'UNKNOWN',
    '路径': 'Path',
    'ECU型号': 'ECU Model',
    '功能场景': 'Functional Scenario',
    'WP.29映射': 'WP.29 Mapping',
    '供应链漏洞列表': 'Supply Chain Vulnerability List',
    '漏洞详情': 'Vulnerability Details',
    'WP.29 R155合规映射': 'WP.29 R155 Compliance Mapping',
    'EPSS评分': 'EPSS Score',
    '发布日期': 'Release Date',
    '所属ECU': 'Owned ECU',
    '零部件代号': 'Part Code',
    '漏洞类型': 'Vulnerability Type',
    '漏洞描述': 'Vulnerability Description',
    '危害级别': 'Hazard Level',
    '影响产品': 'Affect Product',
    'CVE编号': 'CVE Number',
    '参考链接': 'Reference Link',
    '漏洞解决方案': 'Vulnerability Solution',
    '厂商补丁': 'Vendor Patch',
    '漏洞名称': 'Vulnerability Name',
    '漏洞简介': 'Vulnerability Profile',
    '厂商': 'Manufacturer',
    '参考网址': 'Reference Website',
    '官方补丁': 'Official Patch',
    'CVSS(Common Vulnerability Scoring System, 公共漏洞评分系统)是一种用于评估和描述软件漏洞严重性的开放和标准化的系统。CVSS的主要目标是提供一个可公开用于描述软件漏洞严重性的一致和标准化的方法。': 'The Common Vulnerability Scoring System (CVSS) is an open and standardized system for assessing and describing the severity of software vulnerabilities. The main goal of CVSS is to provide a consistent and standardized method that can be publicly used to describe the severity of software vulnerabilities.',
    'CVSS3(Common Vulnerability Scoring System version 3)是公共漏洞评分系统的第三个版本，它是一种标准化的漏洞评分系统，用于评估和描述软件漏洞的严重性。': 'CVSS3(Common Vulnerability Scoring System version 3) is the third version of the Common Vulnerability Scoring System, a standardized vulnerability scoring system used to assess and describe the severity of software vulnerabilities.',
    'CWE,即公共弱点枚举,是一个由社区开发的软件弱点列表。这是一种用于识别和描述软件架构、设计或代码中漏洞的标准。CWE由美国的非营利组织MITRE公司维护,该公司在美国运营着联邦资助的研究和开发中心(FFRDC)。': 'The CWE, or Common Weaknesses Enumeration, is a list of software weaknesses developed by the community. This is a standard used to identify and describe vulnerabilities in software architecture, design, or code. CWE is maintained by MITRE Corporation, a Us-Based non-profit organization that operates federally funded Research and Development Centers (FFRDC) in the United States.',
    'CPE,即公共平台枚举,是一种用于信息技术系统、软件和包的结构化命名方案。它由MITRE开发,是系统安全服务(SCAP)堆栈的一部分，该堆栈规范了我们描述系统及其漏洞的方式。': 'CPE, or Common Platform Enumeration, is a structured naming scheme for information technology systems, software, and packages. Developed by MITRE, it is part of the System Security Services (SCAP) stack, which regulates the way we describe systems and their vulnerabilities.',
    '暴露面分析': 'Exposed Surface Analysis',
    '未分析': 'Not Analyzed',
    '可利用': 'Availability',
    '不可利用': 'Unavailability',
    '存在该漏洞的进程是否开启': 'Whether the process with the vulnerability is started',
    '未开启': 'Unlocked',
    '系统防护导致漏洞对外不可见': 'Vulnerabilities are not visible to the public due to system protection',
    '例如ECU端有防护策略导致漏洞对外不可见': 'For example, there is a protection policy on the ECU. As a result, the vulnerability is not visible',
    '防护': 'Defense',
    '未防护': 'Unguarded',
    '物理接触-物理接口是否存在': 'Physical contact - Whether a physical interface exists',
    '存在': 'Exist',
    '不存在': 'Inexistence',
    '网络连接-网络防护是否存在': 'Network connection - Whether network protection exists',
    '例如防火墙策略': 'For example, firewall policy',
    '该漏洞在车上被利用的相关威胁情报': 'Threat intelligence about the vulnerability being exploited in the vehicle',

    // 威胁情报
    '威胁情报': 'Threat Intelligence',
    '威胁情报列表': 'Threat Intelligence List',
    '公开威胁情报': 'Public Threat Intelligence',
    '漏洞情报': 'Vulnerability Intelligence',
    '漏洞情报详情': 'Vulnerability Intelligence Detail',
    '事件情报': 'Event Information',
    '事件情报详情': 'Event Information Detail',
    '发布时间': 'Publish Time',
    '情报内容': 'Information content',
    '情报源链接': 'Source Link',
    '漏洞标题': 'Vulnerability Title',
    '漏洞等级': 'Vulnerability Level',
    '影响版本': 'Impact Version',
    '影响厂商': 'Influential Firm',
    '公开日期': 'Open Date',
    '更新日期': 'Update Date',
    '推送时间': 'Push Time',
    '事件名称': 'Event Name',
    '标签': 'Tag',
    '作者': 'Author',
    '来源': 'Source',
    '来源链接': 'Source Url',
    '分析结果': 'Analysis Result',

    // 日志管理
    // 平台审计日志
    '当前数据{total}条，默认导出前2000条数据。': 'The current number of data items is {total}. The first 2000 data items are exported by default.',
    '操作日志': 'Operation Log',
    '自审计安全日志': 'Self Audit Security Log',
    '操作时间': 'Time',
    '请求用户': 'User',
    '操作模块': 'Operation Module',
    '操作类型': 'Operation Type',
    '操作结果': 'Result',
    '请求源IP': 'Request Source IP',
    '请求参数': 'Request Parameters',
    '异常类型': 'Exception Type',
    '请求路径': 'Request Path',
    '危险操作': 'Dangerous Operation',
    '异常IP登录': 'Abnormal IP Login',
    '越权访问': 'Unauthorized Access',

    // 安全运维日志
    '安全运维日志': 'Security operation Maintenance logs',
    '模块': 'Module',
    '标题': 'Title',
    '内容': 'Content',

    // 减缓措施配置
    // 告警事件
    '告警描述': 'Alarm Description',
    '最近一次异常发生时间': 'Recent Time',
    '告警名称': 'Alarm Name',
    '告警列表': 'Alarm List',
    '告警分析': 'Alarm Analysis',
    '快速处置': 'Quick Dsisposal',
    '异常处置建议': 'Anomaly Handling Recommendations',
    'UNECE WP.29 R155合规映射': 'UNECE WP.29 R155 compliance mapping',
    '联系木卫四汽车威胁情报中心': 'Contact the Callisto Automotive Threat Intelligence Center',
    '发送邮件：VTI@callisto-auto.com': 'Send Email:VTI@callisto-auto.com',
    '向车端防火墙下发阻断策略，阻断源IP地址请求': 'Send a blocking policy to the car-side firewall to block source IP address requests',
    '减缓措施': 'Mitigation Measures',
    '漏洞处置建议': 'Vulnerability Handling Recommendations',
    '1.对漏洞进行评分和风险评级\n2.对需要立即采取措施的关键漏洞立即处置\n3.连续不断地监控端到端的安全状况，并通过推送系统通知提醒需要关注的问题': '1. Score and assess vulnerabilities for risk\n2. Immediately address critical vulnerabilities that require immediate action\n3. Continuously monitor end-to-end security conditions and alert on issues that require attention through a push notification system',
    'R155漏洞描述': 'R155 Vulnerability Description',
    '忽略漏洞': 'Ignore Vulnerability',
    '忽略原因': 'Ignore Reasons',
    '查看忽略原因': 'View Ignore Reasons',
    '减缓措施配置': 'Mitigation Configuration',
    '安全策略': 'Security Policy',
    '异常ID': 'Anomaly ID',
    '告警ID': 'Alarm ID',
    '告警/异常ID': 'Alarm/Anomaly ID',
    '下发时间': 'Release time',
    '源IP': 'Source IP',
    '目的IP': 'Destination IP',
    '动作': 'Action',
    '生效中': 'In Effect',
    '已删除': 'Deleted',
    '下发成功': 'Issued Successfully',
    '下发失败': 'Issued Failed',
    '处置成功': 'Disposed Successfully',
    '处置失败': 'Disposed Failed',
    '向端防火墙下发阻断策略， 阻断源IP地址请求': 'Send a blocking policy to the end firewall to block source IP address requests',
    '多个IP用逗号分隔，支持单IP，IP段(-连接)，子网。 示例：192.168.1.10,192.168.2.3-192.168.2.100 192.168.3.0/24':
        'Multiple IPs separated by commas, supports single IP, IP range (use - to connect), subnet. '
        + 'Example: 192.168.1.10, 192.168.2.3-192.168.2.100, 192.168.3.0/24',
    '多个端口用逗号分隔，支持端口段, 端口段用:连接。 示例：80,100:200':
        'Multiple ports separated by commas, supports port ranges (use : to connect). Example: 80, 100:200',
    '不在线': 'Offline',


    // 菜单
    '系统菜单': 'System Menu',
    '工单管理': 'Work Order Mgt',
    '风险管理': 'Risk Management',
    '告警详情': 'Alarm Details',
    '异常事件详情': 'Abnormal Event dDetail',
    '异常高级分析': 'Advanced Anomaly Analysis',
    '异常检测': 'Abnormal Detection',
    '安全检测日志': 'Security Detection Logs',
    '平台审计日志': 'Platform Audit Logs',
    '漏洞数据源': 'Vulnerability Data Source',
    '车型详情': 'Vehicle Model Details',
    'ECU型号详情': 'ECU Model Details',
    '供应链漏洞详情': 'Supply Chain Vulnerability Details',
    '漏洞数据源详情': 'Vulnerability Data Source Details',

    // AI助手
    '蝴蝶 V-Copilot': 'Butterfly V-Copilot',
    '蝴蝶': 'Butterfly',
    '提问': 'Ask a Question',
    '发送': 'Send',

    // common
    '个': ' ',
    '序号': 'Num',
    '条': ' ',
    '千条': 'Thousand',
    '次': 'Count',
    '次数': 'Count',
    '新建': 'Add',
    '添加': 'Add',
    '详情': 'Detail',
    '查看': 'View',
    '编辑': 'Edit',
    '删除': 'Delete',
    '批量删除': 'Batch Delete',
    '批量添加': 'Batch Add',
    '确认': 'Confirm',
    '保存': 'Save',
    '取消': 'Cancel',
    '提示': 'Prompt',
    '是否确认删除?': 'Are you sure to delete?',
    '开启': 'Open',
    '关闭': 'Close',
    '返回': 'Back',
    '是': 'Yes',
    '否': 'No',
    '有': 'Have',
    '无': 'Not have',
    '全部': 'All',
    '最近14天': 'Last 14 Days',
    '最近30天': 'Last 30 Days',
    '最近3天': 'Last 3 Days',
    '自定义': 'Customize',
    '至': 'to',
    '显示': 'Show',
    '隐藏': 'Hide',
    '展开': 'Expand',
    '收起': 'Put Away',
    '暂无数据': 'No Data Available',
    '导出': 'Export',
    '筛选': 'Filter',
    '无匹配数据': 'No Options',
    '天': 'Day(s)',
    '小时': 'Hour(s)',
    '秒': 'Second(s)',
    '分': 'Minute(s)',
    '过滤': 'Filter',
    '安装': 'Install',
    '卸载': 'Uninstall',
    '新增': 'New',
    '打开': 'Open',
    '已关闭': 'closed',
    '级别': 'Level',
    '其他': 'Other',

    // validation
    '必填项不能为空': 'This is Required Field',
    '必填项': 'Required Fields',
    '请输入数字': 'Please Enter the Number',
    '请输入正整数': 'Please enter a positive integer',
    '日期格式不正确': 'Date Format is Incorrect',
    '请输入正确的手机格式': 'Please enter the correct mobile phone format',
    '请输入正确的邮箱格式': 'Please enter the correct email format',
    '两次密码不一致': 'The Two Passwords are Inconsistent',
    '值不能重复': 'Value Cannot be Repeated',
    '枚举键值不能重复': 'Enumeration Key Values Cannot be Duplicated',
    '可输入最小值为1': 'The Minimum Value That Can be Entered is 1',
    '可输入最大值为20': 'The Maximum Value That Can be Entered is 20',
    '输入1-300整数': 'Between 1 and 300',
    '请输入整数': 'Please Enter an Integer',
    '请选择文件': 'Please Select a File',
    '请输入大写字母或数字': 'Please Enter Capital Letters or Numbers',
    '请输入17位字符': 'Please Enter 17 Characters',
    '禁止输入中文字符': 'Do not Enter Chinese Characters',
    '密码必须包含字符': 'Password must Include Characters',
    '最小密码长度为{value}位': 'The Minimum Password Length is {value} Characters',
    '密码必须包含数字': 'Password must Contain Numbers',
    '密码必须包含小写字母': 'Password must Contain Lowercase Letters',
    '密码必须包含大写字母': 'Password must Contain Capital Letters',
    '密码必须包含特殊字符': 'Password must Contain Special Characters',
    '异常条件不能为空': 'Exception Condition Cannot be Empty',
    '变量名称至少有一条': 'The Variable Name has at Least One',
    '密码必须包含': 'Password must Contain ',
    '指定角色不能为空': 'The Specified Role Cannot be Empty',
    '日期不能小于等于当前时间': 'The Date Cannot be Less Than or Equal to the Current Time',
    '支持png,jpeg,gif格式，大小在0～1Mb范围内': 'Support png,jpeg,gif format, the size of 0 ~ 1Mb range',
    '支持xls,xlsx格式': 'Supports xls,xlsx formats',
    '图片大小不得超过1M，请重新选择。': 'The size of the picture must not exceed 1M, please select a new one.',
    '类型不合法，请重新选择。': 'The type is invalid. Please select a new one.',
    '输入1-32整数': 'The value is an integer between 1 and 32',
    '输入1-99999整数': 'The value is an integer between 1 and 99999',
    '输入1-60整数': 'The value is an integer between 1 and 60',
    '输入-99999.99~99999.99': 'Enter -99999.99 to 99999.99',
    '保留两位小数点': 'Round to Two Decimal Places',
    '文件大小不能超过100MB': 'File Size Cannot Exceed 100MB',
    '请填写后再新增。': 'Please fill in and add again.',

    // snackbar
    '创建成功': 'Created Successfully',
    '修改成功': 'Modified Successfully',
    '请选择菜单权限': 'Please Select Menu Permissions',
    '修改成功，请重新登录': 'Modified Successfully, Please Log in Again',
    '下发防火墙成功': 'The Firewall Policy is Successfully Installed',
    '下发防火墙失败': 'The Firewall Policy Installation Failed',
    '工单创建成功': 'Ticket Created Successfully',
    '工单处理完毕': 'Ticket Processed',
    '批量创建成功': 'Batch Created Successfully',
    '重置成功': 'Reset Successfully',
    '操作成功': 'Success',
    '取消任务成功': 'Task Canceled',
    '启动任务成功': 'Task Started',
    '重启任务成功': 'Reboot Successfully',
    '任务复制成功': 'Task Cloned Successfully',
    '编辑成功': 'Edited Successfully',
    '添加成功': 'Added Successfully',
    '删除成功': 'Deleted Successfully',
    '误报操作成功': 'False Positive Operation Succeeded',
    '处理人不能为空': 'Processor Cannot be Empty',
    '点击的太快了！！': 'Clicking too Fast! !',
    '登录状态已过期，请重新登录': 'Login Status has Expired, Please Login Again',
    '操作成功，其中失败 {value} 项': 'Operation successful, with {value} items failed',

    // 字典-R155
    '4.3.1与现场车辆有关的后端服务器的威胁': '4.3.1 Threats regarding back-end servers related to vehicles in the field',
    '4.3.2有关其通信渠道的车辆威胁': '4.3.2 Threats to vehicles regarding their communication channels',
    '4.3.3有关车辆更新程序的威胁': '4.3.3. Threats to vehicles regarding their update procedures',
    '4.3.4有关旨在助长网络攻击的意外人为行为的车辆威胁': '4.3.4 Threats to vehicles regarding unintended human actions facilitating a cyber attack',
    '4.3.5有关车辆外部连接和连接的威胁': '4.3.5 Threats to their external connectivity and connections',
    '4.3.6对车辆数据/代码的威胁': '4.3.6 Threats to vehicle data/code',
    '4.3.7如果未充分保护或强化，可能会利用的潜在漏洞': '4.3.7 Potential  vulnerabilities that could be exploited if not sufficiently protected or hardened',
    '1 使用后端服务器作为一种手段来攻击车辆或提取数据': '1 Back-end servers used as a means to attack a vehicle or extract data',
    '2 后端服务器的服务被中断，影响车辆的运行': '2 Services from back-end server being disrupted, affecting the operation of a vehicle',
    '3 后端服务器上保存的与车辆有关的数据丢失或损坏（“数据泄露” ）': '3 Vehicle related data held on back-end servers being lost or compromised (“data breach”)',
    '4 欺骗车辆接收到的消息或数据': '4 Spoofing of messages or data received by the vehicle',
    '5 用于对车载代码/数据进行未经授权的操纵，删除或其他修改的通信渠道': '5 Communication channels used to conduct unauthorized manipulation, deletion or other amendments to vehicle held code/data',
    '6 通信通道允许不信任/不可靠的消息被接受或容易受到会话劫持/重播攻击': '6 Communication channels permit untrusted/unreliable messages to be accepted or are vulnerable to session hijacking/replay attacks',
    '7 信息可以随时披露。例如，通过窃听通信或允许未经授权的访问敏感文件或文件夹': '7 Information can be readily disclosed. For example, through eavesdropping on communications or through allowing unauthorized access to sensitive files or folders',
    '8 通过通信渠道进行的拒绝服务攻击破坏了车辆的功能': '8 Denial of service attacks via communication channels to disrupt vehicle functions',
    '9 非特权用户能够获得对车辆系统的特权访问': '9 An unprivileged user is able to gain privileged access to vehicle systems',
    '10 嵌入通信介质中的病毒能够感染车辆系统': '10 Viruses embedded in communication media are able to infect vehicle systems',
    '11 车辆收到的消息（例如X2V或诊断消息）或在其中传输的消息包含恶意内容': '11 Messages received by the vehicle (for example X2V or diagnostic messages), or transmitted within it, contain malicious content',
    '12 滥用或损害更新程序': '12 Misuse or compromise of update procedures',
    '13 可以拒绝合法更新': '13 It is possible to deny legitimate updates',
    '15 合法参与者可以采取不经意间会助长网络攻击的行动': '15 Legitimate actors are able to take actions that would unwittingly facilitate a cyber_x0002_attack',
    '16 操纵车辆功能的连通性可实现网络攻击，其中可能包括远程信息处理；允许远程操作的系统；和使用短距离无线通信的系统': '16 Manipulation of the connectivity of vehicle functions enables a cyber_x0002_attack, this can include telematics; systems that permit remote operations; and systems using short range wireless communications',
    '17 托管的第三方软件，例如娱乐应用程序，用作攻击车辆系统的一种手段': '17 Hosted 3rd party software, e.g.entertainment applications, used as a means to attack vehicle systems',
    '18 连接到外部接口的设备，例如USB端口，OBD端口，用作攻击车辆系统的手段': '18 Devices connected to external interfaces e.g. USB ports, OBD port, used as a means to attack vehicle systems',
    '19 提取车辆数据/代码': '19 Extraction of vehicle data/code',
    '20 操纵车辆数据/代码': '20 Manipulation of vehicle data/code',
    '21 清除数据/代码': '21 Erasure of data/code',
    '22 恶意软件介绍': '22 Introduction of malware',
    '23 引入新软件或覆盖现有软件': '23 Introduction of new software or overwrite existing software',
    '24 系统或操作中断': '24 Disruption of systems or operations',
    '25 操纵车辆参数': '25 Manipulation of vehicle parameters',
    '26 加密技术可能会受到损害或应用不充分': '26 Cryptographic technologies can be compromised or are insufficiently applied',
    '27 零件或补给品可能被盗用，以使车辆受到攻击': '27 Parts or supplies could be compromised to permit vehicles to be attacked',
    '28 软件或硬件开发允许存在漏洞': '28 Software or hardware development permits vulnerabilities',
    '29 网络设计引入了漏洞': '29 Network design introduces vulnerabilities',
    '30 物理数据丢失': '30 Physical loss of data loss',
    '31 可能会意外传输数据': '31 Unintended transfer of data can occur',
    '32 系统的物理操纵可以发起攻击': '32 Physical manipulation of systems can enable an attack',
    '4.3.1.1.1 工作人员滥用特权（内部攻击）': '4.3.1.1.1 Abuse of privileges by staff (insider attack)',
    '4.3.1.1.2 未经授权的Internet访问服务器（例如，通过后门，未打补丁的系统软件漏洞，SQL攻击或其他方式启用）': '4.3.1.1.2 Unauthorized internet access to the server (enabled for example by backdoors, unpatched system software vulnerabilities, SQL attacks or other means)',
    '4.3.1.1.3 未经授权对服务器进行物理访问（例如，通过USB记忆棒或其他连接到服务器的媒体进行）': '4.3.1.1.3 Unauthorized physical access to the server (conducted by for example USB sticks or other media connecting to the server)',
    '4.3.1.2.1 对后端服务器的攻击会使其停止运行，例如，阻止其与车辆进行交互并提供其所依赖的服务': '4.3.1.2.1 Attack on back-end server stops it functioning, for example it prevents it from interacting with vehicles and providing services they rely on',
    '4.3.1.3.1 员工滥用特权（内部攻击）': '4.3.1.3.1 Abuse of privileges by staff (insider attack)',
    '4.3.1.3.2 云中的信息丢失。第三方云服务提供商存储数据时，敏感数据可能会因攻击或事故而丢失': '4.3.1.3.2 Loss of information in the cloud. Sensitive data may be lost due to attacks or accidents when data is stored by third-party cloud service providers',
    '4.3.1.3.3 未经授权的Internet访问服务器（例如，通过后门，未打补丁的系统软件漏洞，SQL攻击或其他方式启用）': '4.3.1.3.3 Unauthorized internet access to the server(enabled for example by backdoors, unpatched system software vulnerabilities, SQL attacks or other means)',
    '4.3.1.3.4 未经授权对服务器进行物理访问（例如，通过USB记忆棒或其他连接到服务器的媒体进行）': '4.3.1.3.4 Unauthorized physical access to the server(conducted for example by USB sticks or other media connecting to the server)',
    '4.3.1.3.5 意外共享数据导致信息泄露（例如管理员错误）': '4.3.1.3.5 Information breach by unintended sharing of data (e.g. admin errors)',
    '4.3.2.4.1 通过假冒对消息进行欺骗（例如，在分组期间的802.11p V2X ，GNSS消息等）': '4.3.2.4.1 Spoofing of messages by impersonation (e.g. 802.11p V2X during platooning, GNSS messages, etc.)',
    '4.3.2.4.2 Sybil攻击（为了欺骗其他车辆，好像路上有很多车辆）': '4.3.2.4.2 Sybil attack (in order to spoof other vehicles as if there are many vehicles on the road)',
    '4.3.2.5.1 通信通道允许代码注入，例如，可能将篡改的软件二进制文件注入到通信流中': '4.3.2.5.1 Communications channels permit code injection, for example tampered software binary might be injected into the communication stream',
    '4.3.2.5.2 通信通道允许操纵车载数据/代码': '4.3.2.5.2 Communications channels permit manipulate of vehicle held data/code',
    '4.3.2.5.3 通信通道允许覆盖车载数据/代码': '4.3.2.5.3 Communications channels permit overwrite of vehicle held data/code',
    '4.3.2.5.4 通讯通道可清除车辆上的数据/代码': '4.3.2.5.4 Communications channels permit erasure of vehicle held data/code',
    '4.3.2.5.5 通信通道允许向车辆引入数据/代码（写入数据代码）': '4.3.2.5.5 Communications channels permit introduction of data/code to the vehicle (write data code)',
    '4.3.2.6.1 接受来自不可靠或不可信来源的信息': '4.3.2.6.1 Accepting information from an unreliable or untrusted source',
    '4.3.2.6.2 人在中间攻击/会话劫持': '4.3.2.6.2 Man in the middle attack/ session hijacking',
    '4.3.2.6.3 重播攻击，例如对通信网关的攻击，使攻击者可以降级ECU的软件或网关的固件': '4.3.2.6.3 Replay attack, for example an attack against a communication gateway allows the attacker to downgrade software of an ECU or firmware of the gateway',
    '4.3.2.7.1 信息拦截/干扰辐射/监控通信': '4.3.2.7.1 Interception of information / interfering radiations / monitoring communications',
    '4.3.2.7.2 获得对文件或数据的未授权访问': '4.3.2.7.2 Gaining unauthorized access to files or data',
    '4.3.2.8.1 向车辆信息系统发送大量垃圾数据，使其无法正常提供服务': '4.3.2.8.1 Sending a large number of garbage data to vehicle information system, so that it is unable to provide services in the normal manner',
    '4.3.2.8.2 黑洞攻击，为了破坏车辆之间的通信，攻击者能够阻止车辆之间的消息': '4.3.2.8.2 Black hole attack, in order to disrupt communication between vehicles the attacker is able to block messages between the vehicles',
    '4.3.2.9.1 非特权用户能够获得特权访问，例如root访问': '4.3.2.9.1 An unprivileged user is able to gain privileged access, for example root access',
    '4.3.2.10.1 嵌入通信媒体中的病毒会感染车辆系统': '4.3.2.10.1 Virus embedded in communication media infects vehicle systems',
    '4.3.2.11.1 恶意内部消息（例如CAN）': '4.3.2.11.1 Malicious internal (e.g. CAN) messages',
    '4.3.2.11.2 恶意V2X消息，例如车辆或车辆消息的基础结构（例如CAM，DENM）': '4.3.2.11.2 Malicious V2X messages, e.g. infrastructure to vehicle or vehicle-vehicle messages (e.g. CAM, DENM)',
    '4.3.2.11.3 恶意诊断消息': '4.3.2.11.3 Malicious diagnostic messages',
    '4.3.2.11.4 恶意专有消息（例如，通常从OEM或组件/系统/功能供应商发送的消息）': '4.3.2.11.4 Malicious proprietary messages (e.g. those normally sent from OEM or component/system/function supplier)',
    '4.3.3.12.1 损害空中软件更新程序。这包括制作系统更新程序或固件': '4.3.3.12.1 Compromise of over the air software update procedures. This includes fabricating the system update program or firmware',
    '4.3.3.12.2 损害本地/物理软件更新程序。这包括制作系统更新程序或固件': '4.3.3.12.2 Compromise of local/physical software update procedures. This includes fabricating the system update program or firmware',
    '4.3.3.12.3 该软件被更新过程之前操纵（并因此被破坏），虽然更新过程是完整的': '4.3.3.12.3 The software is manipulated before the update process (and is therefore corrupted), although the update process is intact',
    '4.3.3.12.4 破坏软件提供商的加密密钥以允许无效更新': '4.3.3.12.4 Compromise of cryptographic keys of the software provider to allow invalid update',
    '4.3.3.13.1 针对更新服务器或网络的拒绝服务攻击，以防止部署关键软件更新和/或解锁客户特定功能': '4.3.3.13.1 Denial of Service attack against update server or network to prevent rollout of critical software updates and/or unlock of customer specific features',
    '4.3.4.15.1 无辜的受害者（如所有者，经营者或维修工程师）被诱骗采取行动，以无意加载恶意软件或启用攻击': '4.3.4.15.1 Innocent victim (e.g. owner, operator or maintenance engineer) being tricked into taking an action to unintentionally load malware or enable an attack',
    '4.3.4.15.2 未遵循已定义的安全性程序': '4.3.4.15.2 Defined security procedures are not followed',
    '4.3.5.16.1 操纵旨在用于远程操作系统的功能，例如远程钥匙，防盗锁和充电桩': '4.3.5.16.1 Manipulation of the connectivity of vehicle functions enables a cyber_x0002_attack, this can include telematics; systems that permit remote operations; and systems using short range wireless communications',
    '4.3.5.16.2 操纵车辆远程信息处理（例如，操纵敏感货物的温度测量，远程解锁货舱门）': '4.3.5.16.2 Manipulation of vehicle telematics (e.g. manipulate temperature measurement of sensitive goods, remotely unlock cargo doors)',
    '4.3.5.16.3 干扰短距离无线系统或传感器': '4.3.5.16.3 Interference with short range wireless systems or sensors',
    '4.3.5.17.1 损坏的应用程序或软件安全性较差的应用程序被用作攻击车辆系统的方法': '4.3.5.17.1 Corrupted applications, or those with poor software security, used as a method to attack vehicle systems',
    '4.3.5.18.1 USB或其他端口等外部接口被用作攻击点，例如通过代码注入': '4.3.5.18.1 External interfaces such as USB or other ports used as a point of attack, for example through code injection',
    '4.3.5.18.2 媒体感染了与车辆系统连接的病毒': '4.3.5.18.2 Media infected with a virus connected to a vehicle system',
    '4.3.5.18.3 诊断访问（例如，OBD端口中的加密狗）用于促进攻击，例如（直接或间接）操纵车辆参数': '4.3.5.18.3 Diagnostic access (e.g. dongles in OBD port) used to facilitate an attack, e.g. manipulate vehicle parameters (directly or indirectly)',
    '4.3.6.19.1 从车辆系统中提取版权或专有软件（盗版产品）': '4.3.6.19.1 Extraction of copyright or proprietary software from vehicle systems (product piracy)',
    '4.3.6.19.2 未经授权访问所有者的隐私信息，例如个人身份，付款帐户信息，地址簿信息，位置信息，车辆的电子ID等。': '4.3.6.19.2 Unauthorized access to the owner’s privacy information such as personal identity, payment account information, address book information, location information, vehicle’s electronic ID, etc.',
    '4.3.6.19.3 提取加密密钥': '4.3.6.19.3 Extraction of cryptographic keys',
    '4.3.6.20.1 非法/未经授权更改车辆的电子身份证': '4.3.6.20.1 Illegal/unauthorized changes to vehicle’s electronic ID',
    '4.3.6.20.2 身份欺诈。例如，如果用户在与收费系统通信时想要显示另一个身份，则制造商后端': '4.3.6.20.2 Identity fraud. For example, if a user wants to display another identity when communicating with toll systems, manufacturer backend',
    '4.3.6.20.3 行动来回避监测系统（例如黑客/篡改/消息，诸如ODR跟踪数据，或者运行次数的阻塞）': '4.3.6.20.3 Action to circumvent monitoring systems (e.g. hacking/ tampering/ blocking of messages such as ODR Tracker data, or number of runs)',
    '4.3.6.20.4 数据操作到伪造车辆的行驶数据（例如里程，行驶速度，行驶方向等）': '4.3.6.20.4 Data manipulation to falsify vehicle’s driving data(e.g. mileage, driving speed, driving directions, etc.)',
    '4.3.6.20.5 未经授权更改系统诊断数据': '4.3.6.20.5 Unauthorized changes to system diagnostic data',
    '4.3.6.21.1 未经授权删除/操作系统事件日志': '4.3.6.21.1 Unauthorized deletion/manipulation of system event logs',
    '4.3.6.22.2 介绍恶意软件或恶意软件活动': '4.3.6.22.2 Introduce malicious software or malicious software activity',
    '4.3.6.23.1 车辆控制系统或信息系统软件的制造': '4.3.6.23.1 Fabrication of software of the vehicle control system or information system',
    '4.3.6.24.1 拒绝服务，例如，这可能是通过填充CAN总线在内部网络上触发的，或者是通过高消息传递率在ECU上引发故障而触发的': '4.3.6.24.1 Denial of service, for example this may be triggered on the internal network by flooding a CAN bus, or by provoking faults on an ECU via a high rate of messaging',
    '4.3.6.25.1 未经授权擅自使用会篡改车辆关键功能的配置参数，例如制动数据，安全气囊展开阈值等。': '4.3.6.25.1 Unauthorized access of falsify the configuration parameters of vehicle’s key functions, such as brake data, airbag deployed threshold, etc.',
    '4.3.6.25.2 未经授权擅自使用伪造充电参数，例如充电电压，充电功率，电池温度等。': '4.3.6.25.2 Unauthorized access of falsify the charging parameters, such as charging voltage, charging power, battery temperature, etc.',
    '4.3.7.26.1 较短的加密密钥和较长的有效期相结合，使攻击者可以破坏加密': '4.3.7.26.1 Combination of short encryption keys and long period of validity enables attacker to break encryption',
    '4.3.7.26.2 密码算法使用不足，无法保护敏感的词干': '4.3.7.26.2 Insufficient use of cryptographic algorithms to protect sensitive systems',
    '4.3.7.26.3 使用已经或即将弃用的加密算法': '4.3.7.26.3 Using already or soon to be deprecated cryptographic algorithms',
    '4.3.7.27.1 旨在发起攻击或不符合设计标准的硬件或软件，无法阻止攻击': '4.3.7.27.1 Hardware or software, engineered to enable an attack or fails to meet design criteria to stop an attack',
    '4.3.7.28.1 软件错误。软件错误的存在可能是潜在的可利用漏洞的基础。这是尤其如此，如果软件没有经过测试，以验证已知的恶意代码/错误不存在，降低unkno的风险WN糟糕的代码/错误存在': '4.3.7.28.1 Software bugs. The presence of software bugs can be a basis for potential exploitable vulnerabilities. This is particularly true if software has not been tested to verify that known bad code/bugs is not present and reduce the risk of unknown bad code/bugs being present',
    '4.3.7.28.2 使用开发中的其余内容（例如调试端口，JTAG端口，微处理器，开发证书，开发人员密码等）可以允许访问ECU或允许攻击者获得更高的特权': '4.3.7.28.2 Using remainders from development (e.g. debug ports, JTAG ports, microprocessors, development certificates, developer passwords, …) can permit access to ECUs or permit attackers to gain higher privileges',
    '4.3.7.29.1 多余的Internet端口保持打开状态，可访问网络系统': '4.3.7.29.1 Superfluous internet ports left open, providing access to network systems',
    '4.3.7.29.2 规避网络分离以获得控制权。具体示例是使用不受保护的网关或访问点（例如卡车拖车网关）来规避保护并获得对其他网段的访问以执行恶意行为，例如发送任意CAN总线消息': '4.3.7.29.2 Circumvent network separation to gain control. Specific example is the use of unprotected gateways, or access points (such as truck-trailer gateways), to circumvent protections and gain access to other network segments to perform malicious acts, such as sending arbitrary CAN bus messages',
    '4.3.7.30.1 由第三方造成的损坏。在交通事故或盗窃情况下，由于物理损坏，敏感数据可能会丢失或损坏': '4.3.7.30.1 Damage caused by a third party. Sensitive data may be lost or compromised due to physical damages in cases of traffic accident or theft',
    '4.3.7.30.2 DRM （数字版权管理）冲突造成的损失。由于DRM问题，用户数据可能会被删除': '4.3.7.30.2 Loss from DRM (digital right management) conflicts. User data may be deleted due to DRM issues',
    '4.3.7.30.3 敏感数据的（完整性）可能由于IT组件的磨损而丢失，从而导致潜在的级联问题（例如，在密钥更改的情况下）': '4.3.7.30.3 The (integrity of) sensitive data may be lost due to IT components wear and tear, causing potential cascading issues (in case of key alteration, for example)',
    '4.3.7.31.1 信息泄露。当汽车更换用户时，个人数据可能会泄露（例如，出售或与新租车人一起使用了租车）': '4.3.7.31.1 Information breach. Personal data may be leaked when the car changes user (e.g. is sold or is used as hire vehicle with new hirers)',
    '4.3.7.32.1 操纵电子硬件，例如，将未经授权的电子硬件添加到车辆上以进行“中间人”攻击\n将授权的电子硬件（例如，传感器）替换为未授权的电子硬件\n处理传感器收集的信息（例如，使用磁铁篡改连接到变速箱的霍尔效应传感器）': '4.3.7.32.1 Manipulation of electronic hardware, e.g. unauthorized electronic hardware added to a vehicle to enable \'man-in-the-middle\' attackReplacement of authorized electronic hardware (e.g., sensors) with unauthorized electronic hardware Manipulation of the information collected by a sensor (for example, using a magnet to tamper with the Hall effect sensor connected to the gearbox)',
    'M1.安全控制应用于后端系统，以最大程度降低内部攻击的风险': 'Security Controls are applied to back-end systems to minimise the risk of insider attack',
    'M2.安全控制应用于后端系统，以最大程度地减少未经授权的访问。在OWASP中可以找到示例安全控件': 'Security Controls are applied to back-end systems to minimise unauthorised access. Example Security Controls can be found in OWASP',
    'M3.安全控制应用于后端系统。如果后端服务器对于提供服务至关重要，则在系统中断的情况下采取恢复措施。可以在OWASP中找到示例安全控件': 'Security Controls are applied to back-end systems. Where back-end servers are critical to the provision of services there are recovery measures in case of system outage. Example Security Controls can be found in OWASP',
    'M3.安全控制应用于后端系统。当后端服务器是提供服务的关键有[R系统停运的情况下ecovery措施。可以在OWASP中找到示例安全控件': 'Security Controls shall be applied to back-end systems. Where back-end servers are critical to the provision of services there are recovery measures in case of system outage. Example Security Controls can be found in OWASP',
    'M3.安全控制应应用于后端系统。当后端服务器是提供服务的关键有[R系统停运的情况下ecovery措施。可以在OWASP中找到示例安全控件': 'Security Controls shall be applied to back-end systems. Where back-end servers are critical to the provision of services there are recovery measures in case of system outage. Example Security Controls can be found in OWASP',
    'M4.应用安全控制以最小化与云计算相关的风险。可以在OWASP和NCSC云计算指南中找到示例安全控制': 'Security Controls are applied to minimise risks associated with cloud computing. Example Security Controls can be found in OWASP and NCSC cloud computing guidance',
    'M5.安全控制应用于后端系统，以防止数据泄露。可以在OWASP中找到示例安全控件': 'Security Controls are applied to back-end systems to prevent data breaches. Example Security Controls can be found in OWASP',
    'M6.系统应通过设计提高安全性，以最大程度地降低风险': 'Systems shall implement security by design to minimize risks',
    'M7.应当使用访问控制技术和设计来保护系统数据/代码': 'Access control techniques and designs shall be applied to protect system data/code',
    'M7.应当使用访问控制技术和设计来保护系统数据/代码。  可以在OWASP中找到示例安全控件': 'Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP',
    'M7."应当使用访问控制技术和设计来保护系统数据/代码。可以在OWASP中找到示例安全控件。通过关联来自不同信息源的数据，可以减轻对传感器或传输数据的数据操纵攻击': 'Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP.Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information',
    'M7.应当使用访问控制技术和设计来保护系统数据/代码。  可以在OWASP中找到示例安全控件。\n通过关联来自不同信息源的数据，可以减轻对传感器或传输数据的数据操纵攻击': 'Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP.Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information',
    'M7.应当使用访问控制技术和设计来保护系统数据/代码。  可以在OWASP中找到示例安全控件。↵通过关联来自不同信息源的数据，可以减轻对传感器或传输数据的数据操纵攻击': 'Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP.Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information',
    'M7.应当使用访问控制技术和设计来保护系统数据/代码。\n\n可以在OWASP中找到示例安全控件。\n通过关联来自不同信息源的数据，可以减轻对传感器或传输数据的数据操纵攻击': 'Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP.Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information',
    'M7.应当使用访问控制技术和设计来保护系统数据/代码。  可以在OWASP中找到示例安全控件。\n通过关联来自不同信息源的数据，可以减轻对传感器或传输数据的数据操纵攻击': 'Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP.Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information',
    'M8.通过系统设计和访问控制，未经授权的人员不可能访问个人或系统关键数据。可以在OWASP中找到安全控制的示例': 'Through system design and access control it should not be possible for unauthorized personnel to access personal or system critical data. Examples of Security Controls can be found in OWASP',
    'M8.通过系统设计和访问控制，未经授权的人员应不可能访问个人或系统关键数据': 'Through system design and access control it should not be possible for unauthorised personnel to access personal or system critical data',
    'M8.通过系统设计和访问控制，未经授权的人员不可能访问个人或系统关键数据。实例的安全控件可以在OWASP找到': 'Through system design and access control it should not be possible for unauthorized personnel to access personal or system critical data. Example Security Controls can be found in Security Controls can be found in OWASP',
    'M9.应采取措施防止和发现未经授权的访问': 'Measures to prevent and detect unauthorized access shall be employed',
    'M10.车辆应核实收到的消息的真实性和完整性': 'The vehicle shall verify the authenticity and integrity of messages it receives',
    'M10.车辆应核实收到的消息的真实性和完整性。M6.系统应通过设计提高安全性，以最大程度地降低风险': 'M10.The vehicle shall verify the authenticity and integrity of messages it receives.M6.Systems shall implement security by design to minimize risks',
    'M11.应实施安全控制以存储加密密钥': 'Security controls shall be implemented for storing cryptographic keys',
    'M11.必须实施安全控制以存储加密密钥，例如安全模块': 'Security controls shall be implemented for storing cryptographic keys e.g. Security Modules',
    'M11.必须实施安全控制以存储加密密钥（例如，使用硬件安全模块）': 'Security controls shall be implemented for storing cryptographic keys (e.g., use of Hardware Security Modules)',
    'M12.传输至车辆或从车辆传输的机密数据应受到保护': 'Confidential data transmitted to or from the vehicle shall be protected',
    'M13.必须采取措施以检测拒绝服务攻击并从中恢复': 'Measures to detect and recover from a denial of service attack shall be employed',
    'M14.应该考虑采取措施保护系统免受嵌入式病毒/恶意软件的侵害': 'Measures to protect systems against embedded viruses/malware should be considered',
    'M15.应该考虑采取措施检测内部恶意消息或活动': 'Measures to detect malicious internal messages or activity should be considered',
    'M16.应采用安全的软件更新程序': 'Secure software update procedures shall be employed',
    'M16.应实施安全控制以存储加密密钥': 'Secure control should be Implemented for storing encryption keys',
    'M18.根据最小访问权限的原则，应采取措施定义和控制用户角色和访问权限': 'Measures shall be implemented for defining and controlling user roles and access privileges, based on the principle of least access privilege',
    'M19.组织应确保定义并遵循安全程序，包括记录动作和与安全功能管理相关的访问': 'Organizations shall ensure security procedures are defined and followed including logging of actions and access related to the management of the security functions',
    'M20.安全控制措施应应用于具有远程访问权限的系统': 'Security controls shall be applied to systems that have remote access',
    'M21."软件应经过安全评估，认证和完整性保护。 应采用安全控制措施，以最大程度地减少打算或预见将要托管在车辆上的第三方软件的风险': 'Software shall be security assessed, authenticated and integrity protected. Security controls shall be applied to minimise the risk from third party software that is intended or foreseeable to be hosted on the vehicle',
    'M21.软件应经过安全评估，认证和完整性保护。\n应采用安全控制措施，以最大程度地减少打算或预见将要托管在车辆上的第三方软件的风险': 'Software shall be security assessed, authenticated and integrity protected. Security controls shall be applied to minimise the risk from third party software that is intended or foreseeable to be hosted on the vehicle',
    'M22.安全控制措施应应用于外部接口': 'Security controls shall be applied to external interfaces',
    'M23.应当遵循软件和硬件开发的网络安全最佳做法': 'Cybersecurity best practices for software and hardware development shall be followed',
    'M23.应当遵循软件和硬件开发的网络安全最佳做法。 具有足够覆盖范围的网络安全测试': 'Cybersecurity best practices for software and hardware development shall be followed. Cybersecurity testing with adequate coverage',
    'M23.应当遵循软件和硬件开发的网络安全最佳做法。\n具有足够覆盖范围的网络安全测试': 'Cybersecurity best practices for software and hardware development shall be followed. Cybersecurity testing with adequate coverage',
    'M23.应当遵循软件和硬件开发的网络安全最佳做法。具有足够覆盖范围的网络安全测试': 'Cybersecurity best practices for software and hardware development shall be followed. Cybersecurity testing with adequate coverage',
    'M23.应当遵循软件和硬件开发的网络安全最佳做法。遵循系统设计和系统集成的网络安全最佳实践': 'Cybersecurity best practices for software and hardware development shall be followed. Cybersecurity best practices for system design and system integration shall be followed',
    'M23.应当遵循软件和硬件开发的网络安全最佳做法。\n遵循系统设计和系统集成的网络安全最佳实践': 'Cybersecurity best practices for software and hardware development shall be followed. Cybersecurity best practices for system design and system integration shall be followed',
    'M24.存储个人数据时，应遵循保护数据完整性和机密性的最佳做法。可以在ISO / SC27 / WG5中找到示例安全控制': 'Best practices for the protection of data integrity and confidentiality shall be followed for storing personal data. Example Security Controls can be found in ISO/SC27/WG7',
    'M24.存储个人数据时，应遵循保护数据完整性和机密性的最佳做法。': 'Best practices for the protection of data integrity and confidentiality shall be followed for storing personal data.'
};

export default en_US;
