import React from 'react';
import {Outlet} from 'react-router-dom';
import cx from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {changeNavSetting} from '@/store/modules/user/actions';
import {makeStyles} from '@mui/styles';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '100vw'
    },
    main: {
        flexGrow: 1,
        minWidth: 1340,
        height: '100vh',
        padding: `60px ${theme.spacing(2)} ${theme.spacing(2)}`,
        background: theme.palette.background.default
    },
    mainShift: {
        width: 55,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }
}));

const Layout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navSetting = useSelector(state => state.user.navSetting);
    const setSideShow = sideShow => {
        dispatch(changeNavSetting({...navSetting, sideShow}));
    };
    const setSideOpen = sideOpen => {
        dispatch(changeNavSetting({...navSetting, sideOpen}));
    };

    return (
        <div className={classes.root}>
            <Header sideShow={navSetting.sideShow} setSideShow={setSideShow} />
            <Sidebar sideShow={navSetting.sideShow} sideOpen={navSetting.sideOpen} setSideOpen={setSideOpen} />
            <div className={cx(classes.main, {[classes.mainShift]: navSetting.sideOpen})}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
