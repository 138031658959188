import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

// 引入模块化文件
import reducer from './reducer';

// 这里是使用浏览器插件的配置
const composeFn = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeFn(applyMiddleware(thunk));

// 创建并到处仓库
const store = createStore(reducer, enhancer);

export default store;
