import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@mui/material';

const TableTooltip = ({title = '', children}) => {
    const [open, setOpen] = useState(false);

    return (
        <Tooltip
            title={title}
            arrow
            open={open}
            enterDelay={600}
            leaveDelay={0}
            onMouseOver={() => {
                setOpen(true);
            }}
            onMouseLeave={() => {
                setOpen(false);
            }}
            onDoubleClick={() => {
                setOpen(false);
            }}
        >
            {children}
        </Tooltip>
    );
};

TableTooltip.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string
};
export default TableTooltip;
