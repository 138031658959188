export default (
    {
        mode = '',
        primary = null,
        background = null,
        paper = null,
        paperHeader = null,
        popoverBackground = null, // popover弹窗
        dataGridHovered = null,
        headerButtonBackground = null,
        selectedPaper = null,
        partChip = null,
        nav = {}
    }) => {

    // 基础文本
    const text = {dark: 'rgba(0,0,0,0.87)', light: '#fff'};
    // 表格选中背景色
    const dataGridSelected = background;

    return ({
        palette: {
            mode: mode,
            primary: {
                main: primary
            },
            background: {
                default: background, // 默认页面背景
                paper,
                paperHeader,
                dataGridSelected,
                dataGridHovered,
                headerButtonBackground, //  头部操作按钮背景
                selectedPaper, // form下拉选
                partChip // 资产列表-异常chip
            },
            text: {
                ...text
            },
            charts: {
                emptyColor: mode === 'dark' ? '#343434' : '#f5f5f5'
            },
            nav: {
                ...nav
            },
            login: {
                color: '#fff',
                disabledColor: 'rgba(255, 255, 255, 0.26)',
                background: '#000',
                border: 'rgba(255,255,255,0.12)'
            },
            mapSearch: {
                color: text.dark,
                background: '#ffffff'
            }
        },
        typography: {},
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&:disabled': {
                            cursor: 'not-allowed',
                            pointerEvents: 'auto'
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled input': {
                            cursor: 'not-allowed',
                            pointerEvents: 'auto'
                        }
                    },
                    input: {
                        fontSize: 14
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                    SelectProps: {
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 274,
                                    backgroundColor: selectedPaper
                                }
                            }
                        }
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled input': {
                            cursor: 'not-allowed',
                            pointerEvents: 'auto'
                        }
                    }
                }
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small'
                }
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                    square: true
                }
            },
            MuiPopover: {
                defaultProps: {
                    elevation: 0
                },
                styleOverrides: {
                    paper: {
                        backgroundColor: popoverBackground,
                        boxShadow: mode === 'dark'
                            ? 'none'
                            : 'rgb(0 0 0 / 20%) 0px 5px 5px -3px,'
                            + ' rgb(0 0 0 / 14%) 0px 8px 10px 1px,'
                            + ' rgb(0 0 0 / 12%) 0px 3px 14px 2px'
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        ...mode === 'dark' ? {
                            '&.Mui-selected': {
                                backgroundColor: '#494949',
                                '&:hover': {
                                    backgroundColor: '#494949'
                                }
                            }
                        } : {}
                    }
                }
            },
            MuiTreeItem: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        '&.Mui-focused': {
                            background: 'none'
                        }
                    },
                    content: {
                        height: '48px',
                        lineHeight: '48px'
                    },
                    label: {
                        fontSize: '14px',
                        height: '48px',
                        lineHeight: '48px'
                    }
                }
            },
            MuiAppBar: {
                defaultProps: {
                    square: true,
                    elevation: 0
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    regular: {
                        '@media (min-width: 600px)': {
                            minHeight: '60px'
                        }
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    ol: {
                        lineHeight: 1
                    }
                    // separator: {
                    //     color: primary
                    // }
                }
            },
            MuiDrawer: {
                defaultProps: {
                    BackdropProps: {
                        onClick: event => {
                            event.stopPropagation();
                        }
                    }
                },
                styleOverrides: {
                    paper: {
                        borderRight: 'none'
                    }
                }
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: dataGridSelected,
                            '&:hover': {
                                backgroundColor: dataGridSelected
                            }
                        }
                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        '& .scroll': {
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '6px'
                            },
                            '&::-webkit-scrollbar-track': {
                                borderRadius: '3px',
                                backgroundColor: 'transparent'
                            },
                            '&::-webkit-scrollbar-thumb ': {
                                borderRadius: '3px',
                                backgroundColor: mode === 'dark' ? '#222222' : '#EAE6E6'
                            }
                        }
                    }
                }
            }
        }
    });
};
