import {useIntl} from 'react-intl';

export const useTranslation = () => {
    const {formatMessage} = useIntl();

    const t = (id, defaultMessage = '', values = {}) => {
        if (!id || id === ' ') {
            return id;
        }
        return formatMessage({id: id.trim(), defaultMessage: defaultMessage || id.trim()}, values);
    };

    return {t};
};
