import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {SnackbarProvider} from 'notistack';

// redux
import {changePreviousRoute, getUserInfo, getUserSetting} from './store/modules/user/actions';

// js
import ThemeProvider from '@/theme';
import IntlProvider from '@/i18n/intl';
import Router from '@/router';
import {getToken} from '@/utils/storage';
import {checkUpdateToken} from '@/utils/token';
import usePreviousRoute from '@/hooks/usePreviousRoute';

function App() {
    const dispatch = useDispatch();
    const isAuth = getToken();
    const location = useLocation();
    const pathname = location?.pathname || '';
    const prevLocation = usePreviousRoute(location);

    useEffect(() => {
        dispatch(changePreviousRoute(prevLocation));
    }, [pathname]);

    useEffect(() => {
        if (isAuth) {
            // 获取用户信息
            dispatch(getUserInfo());
            // 获取用户设置
            dispatch(getUserSetting());
            // 更新token
            checkUpdateToken();
        }
    }, [isAuth, dispatch]);

    return (
        <ThemeProvider>
            <IntlProvider>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={3000}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Router />
                </SnackbarProvider>
            </IntlProvider>
        </ThemeProvider>
    );
}

export default App;

