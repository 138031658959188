import request from '../utils/request';

export default {
    getCaptcha() {
        return request({
            url: '/sys/oauth/captcha',
            method: 'get'
        });
    },
    login(data) {
        return request({
            url: '/sys/oauth/login',
            method: 'post',
            data: data
        });
    },
    logout() {
        return request({
            url: '/sys/oauth/logout',
            method: 'post'
        });
    },
    updateToken(data) {
        return request({
            url: '/sys/oauth/token/update',
            method: 'post',
            data: data
        });
    },
    updatePassword(data) {
        return request({
            url: '/sys/oauth/update/password',
            method: 'post',
            data: data
        });
    },
    getOauthToken(data) {
        return request({
            url: '/sys/oauth/openLogin',
            method: 'post',
            data: data
        });
    },
    // 重新发送邮件
    sendEmailCode(data) {
        return request({
            url: '/sys/oauth/twoAuthSendCode',
            method: 'post',
            data: data
        });
    },
    // 提交邮件验证
    verifyEmailOauth(data) {
        return request({
            url: '/sys/oauth/twoAuth',
            method: 'post',
            data: data
        });
    }
};
