import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from '@/i18n';
import {makeStyles} from '@mui/styles';
import {AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Divider, Typography, Tooltip} from '@mui/material';
import {
    AccountCircle,
    DarkMode as DarkModeIcon,
    Logout as LogoutIcon,
    Language as LanguageIcon
} from '@mui/icons-material';
import AppTitle from '@/components/AppTitle';
import SvgIcon from '@/components/SvgIcon';
import {logout, updateUserSetting} from '@/store/modules/user/actions';
import AiDrawer from '@/views/chat/AiDrawer';

const useStyles = makeStyles(theme => ({
    appBar: {
        width: '100vw',
        zIndex: theme.zIndex.drawer,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        backgroundColor: theme.palette.nav.background,
        color: theme.palette.nav.color
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    headerCollapse: {
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(2.5)
    }
}));

const Header = ({sideShow, setSideShow}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [aiShow, setAiShow] = useState(false);

    const userSetting = useSelector(state => state.user.userSetting);
    const userInfo = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch();

    const anchorClose = () => {
        setAnchorEl(null);
    };

    const themeChange = () => {
        const newTheme = userSetting.theme === 'dark' ? 'light' : 'dark';
        dispatch(updateUserSetting({...userSetting, theme: newTheme}));
        anchorClose();
    };

    const langChange = () => {
        const newLang = userSetting.language === 'zh-CN' ? 'en-US' : 'zh-CN';
        dispatch(updateUserSetting({...userSetting, language: newLang}));
        anchorClose();
    };

    const signOut = () => {
        dispatch(logout());
    };

    const formatterName = () => {
        const name = userInfo.name;
        const nameLen = name.length || 0;
        if (!name) {
            return '用户名';
        }
        if (nameLen <= 10) {
            return name;
        }
        return (
            <Tooltip title={name}>
                <span>{`${name.substring(0, 10)}...`}</span>
            </Tooltip>
        );
    };

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Tooltip title={t(sideShow ? '隐藏' : '显示')}>
                    <IconButton
                        color="inherit"
                        onClick={() => setSideShow(!sideShow)}
                        className={classes.headerCollapse}
                    >
                        <SvgIcon icon="隐藏" size={20} />
                    </IconButton>
                </Tooltip>
                <Box sx={{flexGrow: 1}}>
                    <AppTitle />
                </Box>

                {/* AI */}
                <Tooltip title={t('蝴蝶 V-Copilot')} sx={{mr: 1}}>
                    <IconButton
                        color="inherit"
                        onClick={() => setAiShow(true)}
                    >
                        <SvgIcon icon='icon_butterfly' size='30px' />
                    </IconButton>
                </Tooltip>

                <Typography variant="body2" onClick={e => setAnchorEl(e.currentTarget)}>
                    <IconButton size="large" color="inherit">
                        <AccountCircle />
                    </IconButton>
                    {formatterName()}
                </Typography>

                {/* 用户 */}
                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={anchorClose}
                    disableAutoFocusItem
                >
                    <MenuItem onClick={themeChange} sx={{fontSize: 12}}>
                        <DarkModeIcon fontSize="small" sx={{mr: 1}} />{t('user.theme')}
                    </MenuItem>
                    <MenuItem onClick={langChange} sx={{fontSize: 12}}>
                        <LanguageIcon fontSize="small" sx={{mr: 1}} />
                        {userSetting.language === 'zh-CN' ? '切换英文' : 'Switch to Chinese'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={signOut} sx={{fontSize: 12}}>
                        <LogoutIcon fontSize="small" sx={{mr: 1}} />{t('user.exit')}
                    </MenuItem>
                </Menu>
            </Toolbar>
            <AiDrawer visible={aiShow} setVisible={setAiShow} />
        </AppBar>
    );
};

Header.propTypes = {
    sideShow: PropTypes.bool,
    setSideShow: PropTypes.func
};

export default Header;

