/**
 * SvgIcon组件配置icon
 *
 * 图标库：https://www.iconfont.cn/
 *
 * 操作注意事项：
 * 1、上传svg的时候选择'去除颜色并提交'
 * 2、svg下载
 *    方式一：单个下载，放到svg文件夹后，可以直接使用
 *    方式二：批量加到购物车后下载，需要手动删除每个svg的fill颜色值，SvgIcon组件的color值才会生效
 */

const req = require.context('./svg', true, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);
