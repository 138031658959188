import _ from 'lodash';
import {Typography} from '@mui/material';
import TableTooltip from '@/components/DataTable/TableTooltip';

/**
 * @param {Array} data 原始list数据
 * @param {string | number} value 待匹配的值
 * @param {string} filterKey 匹配的key，默认id
 * @param {string} propertyKey 要获取的对应属性key，默认name
 * @returns {Exclude<GetFieldType<{}|{}, 'name'>, null | undefined>|string|string}
 */
export function getPropertyValue(data, value, filterKey = 'id', propertyKey = 'name') {
    if (_.isEmpty(data)) {
        return '';
    }
    const obj = _.find(data, item => `${item[filterKey]}` === `${value}`) || {};
    if (!_.isEmpty(obj)) {
        return _.get(obj, propertyKey, '');
    }
    return '';
}

/**
 * 下拉框数据转换
 * @param {Array} data 下拉框原始list数据
 * @param {string} valueKey 转化为value的key
 * @param {string} labelKey 转化为label的key
 * @param {string} valueId 输出value字段名
 * @param {string} valueName 输出label字段名
 * @param {boolean} isExtra 是否保留整体ITEM数据
 * @returns {*[]}
 */
export function handleDropdown(data, valueKey, labelKey, valueId = 'id', valueName = 'name', isExtra = false) {
    const list = [];
    _.forEach(data, item => {
        list.push({
            [valueId]: item[valueKey],
            [valueName]: item[labelKey],
            ...isExtra ? {extra: item} : {}
        });
    });
    return list;
}

/**
 * 下拉框树状结构数据整理
 * @param {Object} prop prop
 * @returns {*[]}
 */

export function handleTreeOption(prop) {
    const {
        data,
        valueName = 'value',
        labelName = 'label',
        childrenName = 'children',
        valueKey = 'value',
        labelKey = 'label',
        childrenKey = 'children',
        extraKey = [],
        isLevel = false
    } = prop;
    const level = 1;

    function iterator(data, itemLevel) {
        const treeOptions = [];
        for (const g of data) {
            let childAry = [];
            if (g[childrenKey] && g[childrenKey].length) {
                childAry = iterator(g[childrenKey], itemLevel + 1);
            }
            const childItemObj = {
                [valueName]: g[valueKey],
                [labelName]: g[labelKey]
            };
            if (!_.isEmpty(childAry)) {
                childItemObj[childrenName] = childAry;
            }
            if (!_.isEmpty(extraKey)) {
                _.forEach(extraKey, key => {
                    childItemObj[key] = g[key];
                });
            }
            if (isLevel) {
                childItemObj.level = itemLevel;
            }
            treeOptions.push(childItemObj);
        }
        return treeOptions;
    }

    return iterator(data, level);
}


/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} hierarchy hierarchy:所有父节点的[hierarchy]字段
 * @param {*} hierarchySuffixKey hierarchy添加的后缀字段
 * @returns {*[]}
 */
export function handleTree({data, id, parentId, children, hierarchy, hierarchySuffixKey = 'id'}) {
    const config = {
        id: id || 'id',
        parentId: parentId || 'parentId',
        childrenList: children || 'children'
    };

    const childrenListMap = {};
    const nodeIds = {};
    const tree = [];

    for (const d of data) {
        const parentId = d[config.parentId];
        if (childrenListMap[parentId] == null) {
            childrenListMap[parentId] = [];
        }
        nodeIds[d[config.id]] = d;
        childrenListMap[parentId].push(d);
    }

    for (const d of data) {
        const parentId = d[config.parentId];
        if (nodeIds[parentId] == null) {
            tree.push(d);
        }
    }

    for (const t of tree) {
        if (hierarchy) {
            const currentHierarchy = t[hierarchy] + t[hierarchySuffixKey];
            t.hierarchy = [currentHierarchy];
        }
        adaptToChildrenList(t);
    }

    function adaptToChildrenList(o) {
        if (childrenListMap[o[config.id]] !== null) {
            o[config.childrenList] = childrenListMap[o[config.id]];
        }
        if (o[config.childrenList]) {
            for (const c of o[config.childrenList]) {
                if (hierarchy) {
                    const currentHierarchy = c[hierarchy] + c[hierarchySuffixKey];
                    c.hierarchy = [
                        ...o.hierarchy || [],
                        currentHierarchy
                    ];
                }
                adaptToChildrenList(c);
            }
        }
    }

    return tree;
}

/**
 * 树形结构转为平铺数组
 * @param {Array} treeData treeData
 * @param {string}  childName childName
 * @returns {*[]}
 */
export function handleTreeToArr(treeData, childName = 'children') {
    const treeList = [];
    loopTree(treeData);

    function loopTree(data) {
        for (const item of data) {
            treeList.push(item);
            if (item[childName]) {
                loopTree(item[childName]);
            }
        }
    }

    return treeList;
}

/**
 * 通过id从树中获取指定的节点
 * @param {Object} node 根节点
 * @param {string|number} id 键值
 * @param {Array} list 保存查询路径
 * @param {string} idKey 主键字段名
 * @param {string} childKey 子节点字段名
 * @returns {*}
 */
function findNode(node, id, list, idKey = 'id', childKey = 'children') {
    if (Array.isArray(list)) {
        list.push(node);
    }
    if (node[idKey] === id) {
        return node;
    }

    if (node[childKey] != null && Array.isArray(node[childKey])) {
        for (const item of node[childKey]) {
            const tempNode = findNode(item, id, list, idKey, childKey);
            if (tempNode) {
                return tempNode;
            }
        }
    }

    if (Array.isArray(list)) {
        list.pop();
    }
}

/**
 * 通过id返回从根节点到指定节点的路径
 * @param {Array} treeRoot 树根节点数组
 * @param {*} id 要查询的节点的id
 * @param {*} idKey 主键字段名
 * @param {*} childKey 子节点字段名
 * @returns {*[]}
 */
export function findTreeNodeObjectPath(treeRoot = [], id, idKey = 'id', childKey = 'children') {
    const tempList = [];
    for (const item of treeRoot) {
        if (findNode(item, id, tempList, idKey, childKey)) {
            return tempList;
        }
    }

    return [];
}

/**
 * 获取取树所有id
 * @param {Array} data data [{id, name, children:[]}]
 * @param {string} idKey id
 * @param {string} childKey children
 * @returns {*[]}
 */
export function handleTreeToAllIds(data = [], idKey = 'id', childKey = 'children') {
    const treeIds = [];
    loopTree(data);

    function loopTree(data) {
        for (const item of data) {
            treeIds.push(String(item[idKey]));
            if (item[childKey]) {
                loopTree(item[childKey]);
            }
        }
    }

    return treeIds;
}


export const switchSizeWithDecimal = (size, dec = 100) => {
    if (!size) {
        return 0;
    }
    if (size < Math.pow(1024, 1)) {
        return size + 'B';
    }
    if (size < Math.pow(1024, 2)) {
        return Math.round((size / Math.pow(1024, 1)) * dec) / dec + 'K';
    }
    if (size < Math.pow(1024, 3)) {
        return Math.round((size / Math.pow(1024, 2)) * dec) / dec + 'M';
    }
    if (size < Math.pow(1024, 4)) {
        return Math.round((size / Math.pow(1024, 3)) * dec) / dec + 'G';
    }
    return Math.round((size / Math.pow(1024, 4)) * dec) / dec + 'T';
};

export const switchCountWithDecimal = (size, dec = 100) => {
    if (!size) {
        return 0;
    }
    if (size < Math.pow(10, 4)) {
        return size;
    }
    if (size < Math.pow(10, 8)) {
        return Math.round((size / Math.pow(10, 4)) * dec) / dec + '万';
    }
    return Math.round((size / Math.pow(10, 8)) * dec) / dec + '亿';
};

export const switchCountWithDecimalAndUnit = (size, dec = 100) => {
    if (!size) {
        return [0];
    }
    if (size < Math.pow(10, 4)) {
        return [size];
    }
    if (size < Math.pow(10, 8)) {
        return [Math.round((size / Math.pow(10, 4)) * dec) / dec, '万'];
    }
    return [Math.round((size / Math.pow(10, 8)) * dec) / dec, '亿'];
};

// vin号转换
export const replaceVin = (vin = '') => {
    if (!vin) {
        return '';
    }
    return (
        <TableTooltip title={vin}>
            <Typography className="ellipsis" component="span">{vin}</Typography>
        </TableTooltip>
    );
};

/**
 * 颜色转换
 * @param {string} bgColor bgColor
 * @param {number} alpha alpha
 * @returns {`rgba(${string})`}
 */
export const handleHexToRgba = (bgColor, alpha = 1) => {
    const color = bgColor.slice(1); // 去掉'#'号
    const rgba = [
        parseInt('0x' + color.slice(0, 2), 16),
        parseInt('0x' + color.slice(2, 4), 16),
        parseInt('0x' + color.slice(4, 6), 16),
        alpha
    ];
    return `rgba(${rgba.toString()})`;
};

/**
 * 数字每三位加逗号处理
 * @param {number} num num
 * @returns {string}
 */
export const numberToLocaleString = num => {
    if (!num) {
        return '0';
    }
    return Number(num).toLocaleString();
};


/**
 * 验证JSON字符串是否符合JSON的标准格式
 * @param {string} str str
 * @returns {boolean|any}
 */
export const isValidJSON = str => {
    try {
        JSON.parse(str);
    }
    catch (error) {
        return false;
    }
    return true;
};


/**
 * 获取hash url中参数
 * @param {string} name name
 * @returns {null|string}
 */
export const getHashParamsString = name => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    const r = window.location.hash.substring(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return null;
};

// 给url添加参数
export const addParametersToPath = (path, params) => {
    const [basePath, queryString] = path.split('?');

    const searchParams = new URLSearchParams(queryString);

    for (const [key, value] of Object.entries(params)) {
        searchParams.set(key, value);
    }

    const newQueryString = searchParams.toString();
    return newQueryString ? `${basePath}?${newQueryString}` : basePath;
};

// 判断是否为合法json
export const isJSON = str => {
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
};