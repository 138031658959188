import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// import {useTranslation} from '@/i18n';
import {
    // Box,
    // Typography,
    // Tooltip,
    // IconButton,
    Drawer
} from '@mui/material';
import {useSelector} from 'react-redux';
import Loading from '@/components/Loading';
import {getDBCurrentRoute, setDBCurrentRoute} from '@/utils/storage';
import {addParametersToPath} from '@/utils/common';
// import SvgIcon from '@/components/SvgIcon';
// import CloseIcon from '@mui/icons-material/Close';
// import AiPage from './index';

const AiDrawer = ({visible, setVisible}) => {
    // const {t} = useTranslation();
    const userSetting = useSelector(state => state.user.userSetting);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        visible && setLoading(true);
    }, [visible]);

    useEffect(() => {
        const handleMessage = event => {
            if (event.origin === window.config.AI_URL) {
                const {type, url} = event.data;
                if (type === 'currentRoute') {
                    setDBCurrentRoute(url);
                }
            }
        };

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const getIframeSrc = () => {
        const path = addParametersToPath(
            getDBCurrentRoute() ?? '/',
            {
                theme_mode: userSetting.theme,
                lng_mode: userSetting.language === 'zh-CN' ? 'zh' : 'en'
            });

        return window.config.AI_URL + path;
    };

    return (
        <Drawer
            className="large-drawer"
            anchor="right"
            open={visible}
            ModalProps={{
                BackdropProps: {
                    onClick: () => {
                        setVisible(false);
                    }
                }
            }}
        >
            {/* <Box
                className='flex-between'
                sx={{
                    height: 60,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    px: 2
                }}
            >
                <Typography className='flex-center'>
                    <SvgIcon icon='icon_butterfly' size='30px' style={{marginRight: 12}} />
                    {t('蝴蝶')}
                </Typography>

                <Tooltip title={t('关闭')}>
                    <IconButton onClick={() => setVisible(false)}><CloseIcon /></IconButton>
                </Tooltip>
            </Box>
            <Box sx={{height: 'calc(100% - 60px)'}}>
                <AiPage />
            </Box> */}

            {loading && <Loading size={80} />}
            <iframe
                id="inlineFrameExample"
                width="100%"
                height="100%"
                style={{border: 'none', display: 'none'}}
                src={getIframeSrc()}
                onLoad={e => {
                    setTimeout(() => {
                        e.target.style.display = 'block';
                        setLoading(false);
                    }, 300);
                }}
            >
            </iframe>
        </Drawer>
    );
};
AiDrawer.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func
};
export default AiDrawer;
