import React, {Suspense, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useRoutes, Navigate} from 'react-router-dom';
import LazyLoad from '@/router/utils/lazyLoad';
import AuthRouter from '@/router/utils/authRouter';
import Loading from '@/components/Loading';

const Router = () => {
    const routerArray = useSelector(state => state.user.permissionMenus);

    const rootRouter = useMemo(() => {
        return [
            {path: '/forward_login', element: <LazyLoad route="login/forwardLogin" />, id: 'forwardLogin'},
            {path: '/invalid_page', element: <LazyLoad route="login/invalidPage" />, id: 'invalidPage'},
            {path: '/login', element: <LazyLoad route="login" />, id: 'login'},
            ...routerArray,
            {path: '*', element: <Navigate to="/" />}
        ];
    }, [JSON.stringify(routerArray)]);

    return (
        <Suspense fallback={<Loading size={80} containerStyle={{height: '100vh'}} />}>
            <AuthRouter>
                {useRoutes(rootRouter)}
            </AuthRouter>
        </Suspense>
    );
};

export default Router;
