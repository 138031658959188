import React, {lazy} from 'react';
import PropTypes from 'prop-types';

const LazyLoad = ({route = 'login', folder = 'views', ...rest}) => {
    const MyComponent = lazy(() => {
        return import(`@/${folder}/${route.substring(0, 1) === '/' ? route.slice(1) : route}`);
    });
    return <MyComponent {...rest} />;
};

LazyLoad.propTypes = {
    route: PropTypes.string,
    folder: PropTypes.string
};
export default LazyLoad;
