import loginApi from '../api/login';
import {setToken, getToken, getExpireTime, setExpireTime} from './storage';
import timeUtil from '@/utils/timeUtil';

/**
 * 60s定时检查token是否过期
 * 提前120s更新token
 */
export function checkUpdateToken() {
    let refreshCount = 0;

    const updateToken = () => {
        refreshCount++;
        loginApi.updateToken({token: getToken()}).then(res => {
            setToken(res.token);
            setExpireTime(res.expireTime);
            refreshCount = 0;
        }).catch(() => {
            if (refreshCount < 5) {
                setTimeout(() => {
                    updateToken();
                }, 500);
            }
        });
    };

    setInterval(() => {
        if ((timeUtil.getCurrentTime() + 120 * 1000 > getExpireTime() * 1000) && refreshCount < 5) {
            updateToken();
        }
    }, 60 * 1000);
}
