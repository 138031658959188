import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import zhLocale from 'date-fns/locale/zh-CN';
import enLocale from 'date-fns/locale/en-US';
import zh_CN from './locale/zh_CN';
import en_US from './locale/en_US';

const i18n = {
    messages: {
        'zh-CN': zh_CN,
        'en-US': en_US
    }
};
const localeMap = {
    'zh-CN': zhLocale,
    'en-US': enLocale
};


const Intl = ({children}) => {
    const lang = useSelector(state => state.user.userSetting.language) || 'zh-CN';

    return (
        <IntlProvider
            key={lang}
            locale={lang}
            messages={i18n.messages[lang]}
            defaultLocale="zh-CN"
            onError={err => {
                if (err.code === 'MISSING_TRANSLATION') {
                    return;
                }
                throw err;
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[lang]}>
                {children}
            </LocalizationProvider>
        </IntlProvider>
    );
};

Intl.propTypes = {
    children: PropTypes.node
};

export default Intl;
