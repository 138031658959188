import axios from 'axios';
import qs from 'qs';
import Message from '@/utils/message';
import {clearAll, getToken} from '@/utils/storage';

const config = {
    BASE_URL: '/vtiApi',
    TIMEOUT: 120000
};

//  创建axios实例
const service = axios.create({
    baseURL: config.BASE_URL,
    timeout: config.TIMEOUT
});

const useRightMessageUrl = [
    '/asset/work/order/save',
    '/asset/work/order/saveRecord',
    '/asset/work/order/updateFix',
    '/asset/report/def/saveOrUpdate',
    '/asset/rule/alarm/def/saveOrUpdate',
    '/sys/sys/user/save',
    '/sys/sys/user/update',
    '/sys/sys/dict/add',
    '/sys/sys/dict/edit',
    '/sys/sys/dict/data/add',
    '/sys/sys/dict/data/edit'
];
const disabledMessageUrl = [
    '/adapter/source/test'
];

function isUseRightErrorMessage(url) {
    return useRightMessageUrl.indexOf(url) > -1;
}

//  request拦截
service.interceptors.request.use(config => {
    /* start--解决重复请求数据覆盖问题-用于DataGrid手动取消请求;暂时固定传值fetchControllerSignal */
    const {data = null} = config;
    const {fetchControllerSignal, ...exceptSignalData} = data || {};
    if (fetchControllerSignal) {
        config.signal = fetchControllerSignal;
        config.data = exceptSignalData;
    }
    /* end */

    if (getToken()) {
        config.headers.oauthToken = getToken();
    }
    if (config.type === 'json') {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    else if (config.type === 'file') {
        config.headers['Content-Type'] = 'application/octet-stream';
    }
    else if (config.type === 'formFile') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.type === 'blob') {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
        config.responseType = 'blob';
    }
    else {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data = qs.stringify(config.data);
    }
    return config;
}, error => {
    Promise.reject(error);
});

// response拦截
service.interceptors.response.use(res => {
    const status = res?.data?.status;
    const message = res?.data?.message;
    const data = res?.data?.data;

    if (status === 0) {
        return res?.data?.data;
    }
    else if (status === 5) {
        Message.error('登录状态已过期，请重新登录');
        clearAll();
        location.reload();
        return Promise.reject(res);
    }
    if (message) {
        if (data !== '点击的太快了！！' && disabledMessageUrl.indexOf(res.config.url) === -1) {
            Message.error(message, isUseRightErrorMessage(res.config.url));
        }
    }
    if (status) {
        return Promise.reject(res);
    }
    return res;
}, error => {
    return Promise.reject(error);
});

export default service;


/**
 * 封装get文件流获取文件预览or下载
 * @param {Object} getFileParams 传值
 * @returns {Promise<unknown>} 预览or下载
 */
export function $getFile(getFileParams) {
    const {url, params = {}, headers = {}, config = {}} = getFileParams;
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params,
            headers: headers,
            responseType: 'blob'
        }).then(response => {
            const blob = new Blob([response.data], {
                type: 'application/pdf;charset=utf-8'
            });
            const fileName = config.fileName || '';
            let url;
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                url = window.navigator.msSaveBlob(blob, fileName);
            }
            else {
                url = window.URL.createObjectURL(blob);
            }
            if (config.download) {
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
            else {
                window.open(url, '_blank');
            }
            resolve(response);
        }, err => {
            reject(err);
        });
    });
}

// 文件流获取文件预览or下载
export function translateBlob(postFileParams) {
    const {data, fileType = 'application/pdf;charset=utf-8', fileName = '', download = true} = postFileParams;
    const blob = new Blob([data], {
        type: fileType
    });
    let url;
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        url = window.navigator.msSaveBlob(blob, fileName);
    }
    else {
        url = window.URL.createObjectURL(blob);
    }
    if (download) {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    else {
        window.open(url, '_blank');
    }
}


