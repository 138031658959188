import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import App from './App';

// js
import './utils/license';
import './assets/icons';
// css
import './style/index.scss';

ReactDOM.render((
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </React.StrictMode>
), document.getElementById('root'));
