import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({size = 40, containerStyle = {}}) => {
    return (
        <div className="wh-100 flex-center" style={{...containerStyle}}>
            <CircularProgress size={size} thickness={1} />
        </div>
    );
};

Loading.propTypes = {
    size: PropTypes.number,
    containerStyle: PropTypes.object
};
export default Loading;
