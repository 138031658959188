import _ from 'lodash';
/**
 * 公共部分
 */

export const publicConstant = {
    nullValue: '—',
    superAdmin: '系统管理员',
    formTips: '请检查填写信息',
    riskLevelColor: {
        0: '#34AA44',
        1: '#40B6DF',
        2: '#FAB314',
        3: '#F97A00',
        4: '#FE4067'
    },
    bugLevelNameColor: {
        '低危': '#40B6DF',
        '中危': '#FAB314',
        '高危': '#F97A00',
        '超危': '#FE4067',
        '待定级': 'rgba(128, 128, 128, 0.3)',
        'LOW': '#40B6DF',
        'MEDIUM': '#FAB314',
        'HIGH': '#F97A00',
        'CRITICAL': '#FE4067',
        'UNKNOWN': 'rgba(128, 128, 128, 0.3)'
    },
    bugLevelObject: {
        'LOW': '低危',
        'MEDIUM': '中危',
        'HIGH': '高危',
        'CRITICAL': '超危',
        'UNKNOWN': '待定级',
        '低': '低危',
        '中': '中危',
        '高': '高危',
        '严重': '超危'
    },
    bugLevelOptions: [
        {id: 'CRITICAL', name: '超危'},
        {id: 'HIGH', name: '高危'},
        {id: 'MEDIUM', name: '中危'},
        {id: 'LOW', name: '低危'},
        {id: 'UNKNOWN', name: '待定级'}
    ],
    riskLevelColorCollection: ['#FE4067', '#F97A00', '#FAB314', '#40B6DF', 'rgba(128, 128, 128, 0.3)'],
    dealStatusOptions: [
        {name: '未处理', id: '0'},
        {name: '处理中', id: '1'},
        {name: '已处理', id: '2'}
    ],
    dealStatusObject: {
        0: '未处理',
        1: '处理中',
        2: '已处理'
    },
    dealStatusColorCollection: [
        '#34AA44',
        '#00B9E4',
        '#FF1C63'
    ],
    dealStatusColor: {
        '0': '#FF1C63',
        '1': '#00B9E4',
        '2': '#34AA44'
    },
    dealStatusNameColor: {
        '未处理': '#FF1C63',
        '处理中': '#00B9E4',
        '已处理': '#34AA44'
    },
    timeOptions: [
        {value: 0, label: '最近1小时'},
        {value: 1, label: '最近24小时'},
        {value: 7, label: '最近7天'},
        {value: 14, label: '最近14天'},
        {value: 30, label: '最近30天'}
    ],
    defaultColumnVisible: {
        ecuList: {
            ecuName: true,
            ecuType: true,
            extUpdate: false,
            factoryHw: false,
            factorySw: false,
            pn: false,
            supplier: true,
            supportOta: true,
            vehicleType: true,
            'aggError.infoList': true
        }
    }
};

/**
 * 漏洞
 */
export const bugConstant = {
    analyzeOptions: [
        {id: '0', name: '未分析'},
        {id: '1', name: '可利用'},
        {id: '2', name: '不可利用'}
    ],
    analyzeObject: {
        0: '未分析',
        1: '可利用',
        2: '不可利用'
    }
};

/**
 * 系统模块
 */
export const sysConstant = {};

/**
 * 报告
 */
export const reportConstant = {
    weekOptions: [
        {id: '1', name: '周日'},
        {id: '2', name: '周一'},
        {id: '3', name: '周二'},
        {id: '4', name: '周三'},
        {id: '5', name: '周四'},
        {id: '6', name: '周五'},
        {id: '7', name: '周六'}
    ],
    monthOptions: _.map(_.range(1, 32), i => {
        return {id: i, name: `${i}号`};
    }),
    monthEnOptions: _.map(_.range(1, 32), i => {
        return {id: i, name: `${i}`};
    }),
    timeOptions: _.map(_.range(24), i => {
        const time = `${i < 10 ? '0' + i : i}:00:00`;
        return {id: time, name: time};
    }),
    quarterOptions: [
        {id: 1, name: '第一季度'},
        {id: 2, name: '第二季度'},
        {id: 3, name: '第三季度'},
        {id: 4, name: '第四季度'}
    ],
    quarterObject: {
        1: '第一季度',
        2: '第二季度',
        3: '第三季度',
        4: '第四季度'
    }
};

/**
 * 工单管理
 */
export const orderConstant = {
    orderStatusOptions: [
        {name: '处理中', id: '1'},
        {name: '已处理', id: '2'}
    ],
    roleTypeOptions: [
        {label: '所有用户', value: '1'},
        {label: '指定角色', value: '2'}
    ],
    processingFlowOptions: [
        {id: 0, name: '异常-创建工单'},
        {id: 1, name: '异常-风险研判'},
        {id: 2, name: '异常-风险处置审批'},
        {id: 3, name: '异常-风险处理'},
        {id: 4, name: '异常-流程结束'},
        // 漏洞流程
        {id: 20, name: '漏洞-创建工单'},
        {id: 21, name: '漏洞-漏洞识别与分类'},
        {id: 22, name: '漏洞-漏洞评估和风险分析'},
        {id: 23, name: '漏洞-漏洞修复和补丁管理'},
        {id: 24, name: '漏洞-漏洞修复验证'},
        {id: 25, name: '漏洞-流程结束'}
    ],
    processingFlowObject: {
        0: '创建工单',
        1: '风险研判',
        2: '风险处置审批',
        3: '风险处理',
        4: '流程结束', // 暂定结束
        // 漏洞流程
        20: '创建工单',
        21: '漏洞识别与分类',
        22: '漏洞评估和风险分析',
        23: '漏洞修复和补丁管理',
        24: '漏洞修复验证',
        25: '流程结束'
    },
    protocolOptions: [{id: 'TCP', name: 'TCP'}, {id: 'UDP', name: 'UDP'}, {id: 'ICMP', name: 'ICMP'}],
    actionOptions: [
        {id: 'ACCEPT', name: '接受'},
        {id: 'DROP', name: '丢弃'},
        {id: 'RETURN', name: '返回'}
    ],
    actionObject: {
        ACCEPT: '接受',
        DROP: '丢弃',
        RETURN: '返回'
    },
    issueStatusOptions: [
        {id: 1, name: '下发成功'},
        {id: 2, name: '下发失败'},
        {id: 3, name: '处置成功'},
        {id: 4, name: '处置失败'}
    ],
    issueStatusObject: {
        1: '下发成功',
        2: '下发失败',
        3: '处置成功',
        4: '处置失败'
    }
};

// 数据源模块
export const adapterConstant = {
    protocolOptions: [
        {id: 'kafka', name: 'kafka'},
        {id: 'mqtt', name: 'mqtt'},
        {id: 'json-line', name: 'json-line'},
        {id: 'csv', name: 'csv'},
        {id: 'csv-dir', name: 'csv-dir'},
        {id: 'hwiot', name: 'Huawei-IoTDA'}
    ],
    protocolParamsConfig: {
        'kafka': {
            'extendable': true,
            'props': [
                {
                    'key': 'bootstrap.servers',
                    'label': '服务地址',
                    'placeholder': 'Kafka服务器地址和端口，多个用逗号分割, eg: 192.168.1.2:9092,192.168.1.3:9092',
                    'type': 'input',
                    maxLength: 500,
                    'required': true
                },
                {
                    'key': 'topic',
                    'label': '主题(topic)',
                    'placeholder': 'Kafka数据主题',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'group.id',
                    'label': '消费组名称',
                    'placeholder': '由英文字母、数字、横杠、下划线组成',
                    'type': 'input',
                    'required': true
                }
            ]
        },
        'csv': {
            'props': [
                {
                    'key': 'fileType',
                    'label': '文件类型',
                    'placeholder': '文件源类型',
                    'type': 'select',
                    'options': [
                        {
                            'label': '服务器本地文件',
                            'value': 'local'
                        },
                        {
                            'label': 'minio服务器文件',
                            'value': 'minio'
                        }
                    ],
                    'required': true
                },
                {
                    'key': 'filePath',
                    'label': '文件路径',
                    'placeholder': '输入文件路径，minio文件输入bucket(不含)后到路径部分',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'withHeader',
                    'label': '头信息',
                    'placeholder': '文件中是否包含头信息',
                    'type': 'select',
                    'options': [
                        {
                            'label': '文件中包含头信息',
                            'value': '1'
                        },
                        {
                            'label': '文件中无头信息',
                            'value': '0'
                        }
                    ],
                    'required': true
                },
                {
                    'key': 'headers',
                    'label': 'csv 头信息',
                    'placeholder': '多个字段名按顺序填写，用逗号,分割',
                    'type': 'input',
                    maxLength: 500,
                    'required': false
                }
            ]
        },
        'csv-dir': {
            'props': [
                {
                    'key': 'fileType',
                    'label': '文件类型',
                    'placeholder': '文件源类型',
                    'type': 'select',
                    'options': [
                        {
                            'label': '服务器本地文件',
                            'value': 'local'
                        },
                        {
                            'label': 'minio服务器文件',
                            'value': 'minio'
                        }
                    ],
                    'required': true
                },
                {
                    'key': 'filePath',
                    'label': '文件夹路径',
                    'placeholder': '输入文件路径，minio文件输入bucket(不含)后到路径部分',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'withHeader',
                    'label': '头信息',
                    'placeholder': '文件中是否包含头信息',
                    'type': 'select',
                    'options': [
                        {
                            'label': '文件中包含头信息',
                            'value': '1'
                        },
                        {
                            'label': '文件中无头信息',
                            'value': '0'
                        }
                    ],
                    'required': true
                },
                {
                    'key': 'headers',
                    'label': 'csv 头信息',
                    'placeholder': '若文件中不包含头信息，请填写。多个字段名按顺序填写，用逗号,分割',
                    'type': 'input',
                    maxLength: 500,
                    'required': false
                },
                {
                    'key': 'recursive',
                    'label': '子目录',
                    'placeholder': '是否遍历子目录',
                    'type': 'select',
                    'options': [
                        {
                            'label': '遍历子目录',
                            'value': '1'
                        },
                        {
                            'label': '仅当前目录',
                            'value': '0'
                        }
                    ]
                },
                {
                    'key': 'fileSuffix',
                    'label': '文件名后缀',
                    'placeholder': '默认为 csv, 如不限后缀名请留空',
                    'type': 'input',
                    'required': false
                }
            ]
        },
        'json-line': {
            'props': [
                {
                    'key': 'fileType',
                    'label': '文件类型',
                    'placeholder': '文件源类型',
                    'type': 'select',
                    'options': [
                        {
                            'label': '服务器本地文件',
                            'value': 'local'
                        },
                        {
                            'label': 'minio服务器文件',
                            'value': 'minio'
                        }
                    ],
                    'required': true
                },
                {
                    'key': 'filePath',
                    'label': '文件路径',
                    'placeholder': '输入文件路径，minio文件输入bucket(不含)后到路径部分',
                    'type': 'input',
                    'required': true
                }
            ]
        },
        'mqtt': {
            'extendable': true,
            'props': [
                {
                    'key': 'broker',
                    'label': '服务器地址',
                    'placeholder': '服务器地址，如tcp://localhost:18883',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'username',
                    'label': '用户名',
                    'placeholder': '认证用户名',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'password',
                    'label': '密码',
                    'placeholder': '认证密码',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'topic',
                    'label': '消息主题',
                    'placeholder': '支持通配符，如：/vsoc/#',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'cert-ca',
                    'label': 'CA根证书',
                    'placeholder': 'CA根证书内容，支持换行，通常以 -----BEGIN CERTIFICATE----- 开头，'
                        + '以 -----END CERTIFICATE----- 结尾',
                    'type': 'textarea',
                    'maxRows': 8,
                    maxLength: 5000,
                    'required': false
                },
                {
                    'key': 'cert-client',
                    'label': '客户端证书',
                    'placeholder': '客户端证书，支持换行，通常以 -----BEGIN CERTIFICATE----- 开头'
                        + '，以 -----END CERTIFICATE----- 结尾',
                    'type': 'textarea',
                    'maxRows': 8,
                    maxLength: 5000,
                    'required': false
                },
                {
                    'key': 'cert-key',
                    'label': '证书key',
                    'placeholder': '证书key，支持换行，通常以 -----BEGIN RSA PRIVATE KEY----- 开头，'
                        + '以 -----END RSA PRIVATE KEY----- 结尾',
                    'type': 'textarea',
                    'maxRows': 8,
                    maxLength: 5000,
                    'required': false
                },
                {
                    'key': 'msg-decoder-class',
                    'label': '消息解析类',
                    'placeholder': '消息解析类，如：com.callisto.adapter.proto.decoder.IdpsDecoder',
                    'type': 'input',
                    maxLength: 500,
                    'required': false
                },
                {
                    'key': 'msg-proto-desc-base64',
                    'label': 'Protobuf描述文件',
                    'placeholder': '描述文件内容，二进制文件进行base64编码后的字符串',
                    'type': 'textarea',
                    maxLength: 5000,
                    'maxRows': 10,
                    'required': false
                }
            ]
        },
        'hwiot': {
            'extendable': true,
            'props': [
                {
                    'key': 'broker',
                    'label': '服务器地址',
                    'placeholder': '服务器地址，如test.myhuaweicloud.com',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'access-key',
                    'label': '访问密钥',
                    'placeholder': '访问密钥',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'password',
                    'label': '访问密码',
                    'placeholder': '访问密码',
                    'type': 'input',
                    'required': true
                },
                {
                    'key': 'queue',
                    'label': '消息队列名称',
                    'placeholder': '消息队列名称，如test-message',
                    'type': 'input',
                    'required': true
                }
            ]
        }
    },
    formatOptions: [
        {id: 'JSON', name: 'JSON'},
        {id: 'CSV', name: 'CSV'},
        {id: 'MARKDOWN_TABLE', name: 'MARKDOWN_TABLE'}
    ],
    dataTypeOptions: [
        {id: 'BOOL', name: 'BOOL'},
        {id: 'CONST', name: 'CONST'},
        {id: 'DATETIME', name: 'DATETIME'},
        {id: 'DOUBLE', name: 'DOUBLE'},
        {id: 'ENUM', name: 'ENUM'},
        {id: 'JSON', name: 'JSON'},
        {id: 'LONG', name: 'LONG'},
        {id: 'MAP', name: 'MAP'},
        {id: 'STRING', name: 'STRING'}
    ]
};

// 日志管理
export const logConstant = {
    exceptionType: {
        1: '危险操作',
        2: '异常IP登录',
        3: '越权访问'
    },
    exceptionTypeOptions: [
        {id: '1', name: '危险操作'},
        {id: '2', name: '异常IP登录'},
        {id: '3', name: '越权访问'}
    ]
};
