import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {zhCN, enUS} from '@mui/material/locale';
import {zhCN as zhCNDataGrid, enUS as enUSDataGrid} from '@mui/x-data-grid';
import {zhCN as zhCNDatePickers, enUS as enUSDatePickers} from '@mui/x-date-pickers/locales';

import themeFrame from './themes';

const themeMap = {
    dark: themeFrame({
        mode: 'dark',
        primary: '#90caf9',
        background: '#3a3a3a',
        paper: '#2c2c2c',
        paperHeader: '#1c1c1c',
        popoverBackground: '#3d3d3d',
        dataGridHovered: 'rgba(255, 255, 255, 0.08)',
        headerButtonBackground: 'rgba(144, 202, 249, 0.08)',
        selectedPaper: '#2c2c2c',
        partChip: '#525252',
        nav: {
            color: '#fff',
            background: '#000',
            sideColor: '#fff',
            sideBackground: '#000',
            selected: '#1c1c1c'
        }
    }),
    light: themeFrame({
        mode: 'light',
        primary: '#1976d2',
        background: '#F6F6F6',
        paper: '#ffffff',
        paperHeader: '#ffffff',
        popoverBackground: '#ffffff',
        dataGridHovered: 'rgba(0, 0, 0, 0.04)',
        headerButtonBackground: 'rgba(25, 118, 210, 0.04)',
        selectedPaper: '#ffffff',
        partChip: '#EAE6E6',
        nav: {
            color: '#fff',
            background: '#000',
            sideColor: '#000',
            sideBackground: '#ECECEC',
            selected: '#ECECEC'
        }
    })
};

const zhCNOverride = {
    components: {
        MuiLocalizationProvider: {
            defaultProps: {
                localeText: {
                    start: '开始时间',
                    end: '结束时间'
                }
            }
        },
        MuiDataGrid: {
            defaultProps: {
                localeText: {
                    filterOperatorDepthContains: '包含'
                }
            }
        }
    }
};
const enUSOverride = {
    components: {
        MuiDataGrid: {
            defaultProps: {
                localeText: {
                    filterOperatorDepthContains: 'contains'
                }
            }
        }
    }
};

const langMap = {
    'zh-CN': _.merge({}, zhCN, zhCNDataGrid, zhCNDatePickers, zhCNOverride),
    'en-US': _.merge({}, enUS, enUSDataGrid, enUSDatePickers, enUSOverride)
};

const Index = ({children, fixTheme}) => {
    const userSetting = useSelector(state => state.user.userSetting);

    const muiTheme = createTheme(
        themeMap[fixTheme || userSetting.theme],
        langMap[userSetting.language]
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

Index.propTypes = {
    children: PropTypes.node,
    fixTheme: PropTypes.string
};

export default Index;
