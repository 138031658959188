import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {makeStyles, useTheme} from '@mui/styles';

const useStyles = makeStyles(() => ({
    svgIcon: {
        fill: 'currentColor',
        overflow: 'hidden'
        // verticalAlign: 'middle'
    },
    svgExternalIcon: {
        backgroundColor: 'currentColor',
        maskSize: 'cover !important',
        display: 'inline-block'
    }
}));


const SvgIcon = ({icon, className, color, size, style, fillType = '', onClick}) => {
    const classes = useStyles();
    const theme = useTheme();

    const styleExternalIcon = {
        mask: `url(${icon}) no-repeat 50% 50%`,
        WebkitMask: `url(${icon}) no-repeat 50% 50%`
    };
    const isExternal = path => /^(https?:|mailto:|tel:)/.test(path);

    const fillColorObject = {
        'dark': theme.palette.text.dark,
        'light': theme.palette.text.light,
        'system': theme.palette.text.primary,
        'primary': theme.palette.primary.main
    };

    const viewColor = () => {
        if (color) {
            return color;
        }
        return fillColorObject[fillType] || '';
    };

    return (
        <>
            {
                isExternal(icon) ? <div
                    style={{...styleExternalIcon, ...style}}
                    className={cx(classes.svgExternalIcon, classes.svgIcon, className)}
                /> : (
                    <svg
                        className={cx(classes.svgIcon, className)}
                        aria-hidden="true"
                        style={{
                            color: viewColor(),
                            width: size ? size : '20px',
                            height: size ? size : '20px',
                            cursor: onClick ? 'pointer' : 'default',
                            ...style
                        }}
                        onClick={onClick || null}
                    >
                        <use xlinkHref={`#icon-${icon}`} />
                    </svg>
                )
            }
        </>
    );
};

SvgIcon.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    color: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    style: PropTypes.object,
    fillType: PropTypes.string,
    onClick: PropTypes.func
};
export default SvgIcon;
