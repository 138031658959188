import _ from 'lodash';
import {
    CHANGE_PERMISSION_BUTTONS,
    CHANGE_PERMISSION_MENUS,
    CHANGE_USERINFO,
    CHANGE_USERSETTING,
    CHANGE_CACHE_ROUTERS, CHANGE_NAV_SETTING, CHANGE_PREVIOUS_ROUTE
} from './action-types';
import {getToken, clearAll} from '@/utils/storage';
import loginApi from '@/api/login';
import userApi from '@/api/user';
import menuRoute from '@/router/utils/menuRoute';

export const changeUserInfo = data => ({type: CHANGE_USERINFO, data: data});
export const changeUserSetting = data => ({type: CHANGE_USERSETTING, data: data});
export const changePermissionMenus = data => ({type: CHANGE_PERMISSION_MENUS, data: data});
export const changePermissionButtons = data => ({type: CHANGE_PERMISSION_BUTTONS, data: data});
export const changeCacheRouters = data => ({type: CHANGE_CACHE_ROUTERS, data: data});
export const changeNavSetting = data => ({type: CHANGE_NAV_SETTING, data: data});
export const changePreviousRoute = data => ({type: CHANGE_PREVIOUS_ROUTE, data: data});

export const asyncLogin = data => {
    return () => {
        return new Promise((resolve, reject) => {
            loginApi.login(data).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    };
};

export const getUserInfo = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            userApi.getUserInfo().then(res => {
                dispatch(changeUserInfo(res));

                dispatch(changePermissionMenus(menuRoute(res?.menu || [])));

                const buttons = _.fromPairs(
                    _.map(res?.menu || [], item => ([item.permissionCode, item.permissionLevel]))
                );
                dispatch(changePermissionButtons(buttons));

                const cacheRouters = _.map(_.filter(res?.menu || [], i => !!i.cache), j => j.url);
                dispatch(changeCacheRouters(cacheRouters));

                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    };
};

export const getUserSetting = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            userApi.getUserSetting({type: 1}).then(res => {
                dispatch(changeUserSetting(res));
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    };
};

export const updateUserSetting = data => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            userApi.getUserSetting({type: 2, map: data}).then(res => {
                dispatch(changeUserSetting(res));
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    };
};

export const logout = () => {
    return () => {
        return new Promise((resolve, reject) => {
            loginApi.logout({token: getToken()}).then(() => {
                clearAll();
                location.reload();
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    };
};
