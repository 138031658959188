import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from '@/i18n';
import {makeStyles} from '@mui/styles';
import {ExpandLess, ExpandMore, ChevronLeft, ChevronRight} from '@mui/icons-material';
import {MenuList, MenuItem, ListItemIcon, ListItemText, Collapse, Drawer, Tooltip} from '@mui/material';
import SvgIcon from '@/components/SvgIcon';
import {useMenuData} from '../menu';
import {handleTreeToArr} from '@/utils/common';

const drawerWidth = 256;
const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'relative'
    },
    drawerPaper: {
        paddingBottom: theme.spacing(8),
        border: 'none',
        backgroundColor: theme.palette.nav.sideBackground,
        color: theme.palette.nav.sideColor,
        zIndex: theme.zIndex.drawer - 1
    },
    drawerOpen: {
        width: drawerWidth
    },
    drawerClose: {
        width: 60,
        overflowX: 'hidden'
    },
    drawerHide: {
        width: 0,
        overflowX: 'hidden'
    },
    drawerTrans: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuScroll: {
        width: '100%',
        height: '100%',
        overflowY: 'auto'
    },
    sidebarLogo: {
        width: '100%',
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 100
    },
    bottomCollapse: {
        width: '100%',
        position: 'absolute',
        bottom: theme.spacing(2),
        height: 48
    },
    toolbar: {
        ...theme.mixins.toolbar,
        '@media (min-width: 600px)': {
            minHeight: '60px'
        }
    },
    menuItem: {
        height: 48,
        fontWeight: 500,
        fontSize: 14,
        '&.Mui-selected': {
            backgroundColor: theme.palette.nav.selected,
            color: theme.palette.primary.main
        }
    },
    menuItemText: {
        fontWeight: 500,
        fontSize: 14,
        transition: theme.transitions.create(['opacity', 'color'])
    },
    menuItemIcon: {
        transition: theme.transitions.create(['opacity', 'color'])
    },
    menuItemHidden: {
        opacity: 0
    },
    menuItemSide: {
        width: 4,
        height: 48,
        minWidth: 4
    },
    menuItemSideColor: {
        backgroundColor: '#90caf9'
    },
    menuIcon: {
        color: theme.palette.text.primary
    },
    menuIconSelected: {
        color: theme.palette.primary.main
    },
    popperPaper: {
        marginTop: theme.spacing(-1)
    },
    popperMenu: {
        minWidth: 300,
        backgroundColor: theme.palette.nav.sideBackground,
        color: theme.palette.nav.sideColor,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
    },
    popperMenuTitle: {
        height: 48,
        marginBottom: theme.spacing(1),
        fontSize: theme.typography.h6.fontSize
    },
    popperMenuItem: {
        height: 48,
        fontSize: theme.typography.body1.fontSize,
        '&Mui-selected': {
            backgroundColor: theme.palette.nav.sideBackground
        }
    }
}));

const uniqueOpened = true; // 是否只保持一个子菜单的展开

const MenuModule = ({i, parentId, sideOpen, collapseKey, setCollapseKey, setMouseBar}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const selected = location.pathname.includes(i.path);
    const [open, setOpen] = useState({activities: false});
    const childIds = _.map(i.children, j => j.id);

    useEffect(() => {
        if (selected) {
            const menuParent = _.filter(handleTreeToArr([i]), item => item.children && item.id === i.id);
            !_.isEmpty(menuParent) && setCollapseKey(menuParent[0].id);
        }
    }, [selected]);

    const toggleCollapse = (e, key) => {
        e.preventDefault();
        if (uniqueOpened) {
            if (collapseKey === i.id && parentId) {
                setCollapseKey(parentId);
            }
            else if (collapseKey === i.id || childIds.includes(collapseKey)) {
                setCollapseKey(null);
            }
            else {
                setCollapseKey(i.id);
            }
        }
        else {
            setOpen({...open, [key]: !open[key]});
        }
    };

    // 菜单-自定义是否隐藏
    if (i.meta?.hidden) {
        return null;
    }

    if (!i.children) {
        return (
            <MenuItem
                classes={{root: classes.menuItem}}
                component={Link}
                to={i.path}
                dense
                selected={selected}
                sx={{pl: 0}}
                onClick={() => setMouseBar(false)}
            >
                <div className={cx(classes.menuItemSide, {[classes.menuItemSideColor]: selected})} />
                <ListItemIcon
                    sx={{ml: 2}}
                    classes={{root: cx(classes.menuIcon, {[classes.menuIconSelected]: selected})}}
                >
                    <SvgIcon icon={i.meta.icon} size={20} />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: cx(classes.menuItemText, 'ellipsis-span', {
                            [classes.menuItemHidden]: !sideOpen
                        })
                    }}
                    primary={t(i?.meta?.title)}
                />
            </MenuItem>
        );
    }

    return (
        <>
            {/* 包含有二级的菜单 */}
            <MenuItem
                classes={{root: classes.menuItem}}
                dense
                sx={{pl: 0}}
                selected={!sideOpen && selected}
                onClick={e => toggleCollapse(e, 'activities')}
            >
                <div className={cx(classes.menuItemSide, {[classes.menuItemSideColor]: !sideOpen && selected})} />
                <ListItemIcon
                    sx={{ml: 2}}
                    classes={{root: cx(classes.menuIcon, {[classes.menuIconSelected]: selected})}}
                >
                    <SvgIcon icon={i.meta.icon} size={20} />
                </ListItemIcon>

                <ListItemText
                    classes={{
                        primary: cx(classes.menuItemText, 'ellipsis-span', {
                            [classes.menuItemHidden]: !sideOpen
                        })
                    }}
                    primary={t(i?.meta?.title)}
                />
                {open.activities || collapseKey === i.id || childIds.includes(collapseKey)
                    ? <ExpandLess className={cx(classes.menuItemIcon, {
                        [classes.menuItemHidden]: !sideOpen
                    })}
                    />
                    : <ExpandMore className={cx(classes.menuItemIcon, {
                        [classes.menuItemHidden]: !sideOpen
                    })}
                    />
                }
            </MenuItem>
            <Collapse
                in={(open.activities || collapseKey === i.id || childIds.includes(collapseKey)) && sideOpen}
                timeout="auto"
                unmountOnExit
                className={classes.nestedList}
            >
                <MenuList component="nav" disablePadding>
                    {_.map(i.children, child => (
                        <MenuModule
                            key={child.id}
                            i={child}
                            parentId={i.id}
                            sideOpen={sideOpen}
                            collapseKey={collapseKey}
                            setCollapseKey={setCollapseKey}
                            setMouseBar={setMouseBar}
                        />
                    ))}
                </MenuList>
            </Collapse>
        </>
    );
};
MenuModule.propTypes = {
    i: PropTypes.object,
    parentId: PropTypes.string,
    sideOpen: PropTypes.bool,
    collapseKey: PropTypes.string,
    setCollapseKey: PropTypes.func,
    setMouseBar: PropTypes.func
};

const Sidebar = ({sideShow, sideOpen, setSideOpen}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [mouseBar, setMouseBar] = useState(false);
    const [collapseKey, setCollapseKey] = useState(null);

    function getBarClass() {
        if (sideShow) {
            if (sideOpen || mouseBar) {
                return classes.drawerOpen;
            }
            return classes.drawerClose;
        }
        return classes.drawerHide;
    }

    return (
        <Drawer
            variant="permanent"
            className={cx(classes.drawer, {
                [classes.drawerOpen]: sideOpen && sideShow,
                [classes.drawerClose]: !sideOpen && sideShow,
                [classes.drawerHide]: !sideShow
            }, classes.drawerTrans)}
            classes={{
                paper: cx(classes.drawerPaper, getBarClass(), classes.drawerTrans)
            }}
            sx={{borderColor: 'nav.sideBackground'}}
            open={sideOpen}
        >
            <div
                className={classes.menuScroll}
                onMouseEnter={() => setMouseBar(true)}
                onMouseLeave={() => setMouseBar(false)}
            >
                <div className={classes.toolbar} />
                <MenuList sx={{pt: 0}}>
                    {_.map(useMenuData(), item => (
                        <MenuModule
                            key={item.id}
                            i={item}
                            sideOpen={sideOpen || mouseBar}
                            collapseKey={collapseKey}
                            setCollapseKey={setCollapseKey}
                            setMouseBar={setMouseBar}
                        />
                    ))}
                </MenuList>

                <Tooltip title={t(sideOpen ? '收起' : '展开')}>
                    <MenuItem className={classes.bottomCollapse} onClick={() => setSideOpen(!sideOpen)}>
                        {sideOpen ? <ChevronLeft /> : <ChevronRight />}
                    </MenuItem>
                </Tooltip>
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    sideShow: PropTypes.bool,
    sideOpen: PropTypes.bool,
    setSideOpen: PropTypes.func
};
export default Sidebar;

