import React, {useEffect} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Navigate, useLocation, useSearchParams} from 'react-router-dom';
import {getToken, setExpireTime, setToken} from '@/utils/storage';
import {getHashParamsString} from '@/utils/common';
import Loading from '@/components/Loading';
import {useTranslation} from '@/i18n';

const initTitle = 'Vehicle Threat Intelligence';
const AuthRouter = ({children}) => {
    const {t} = useTranslation();
    const isAuth = getToken();
    const location = useLocation();
    const routerArray = useSelector(state => state.user.permissionMenus);
    const userInfo = useSelector(state => state.user.userInfo);
    const [params] = useSearchParams();
    const token = params.get('token');
    const expireTime = params.get('expireTime');

    /* 态势部分-动态Title显示 */
    useEffect(() => {
        if (_.isEmpty(routerArray)) {
            return;
        }
        const pathInfo = _.find(routerArray, i => i.path === location.pathname);
        document.title = (pathInfo && pathInfo.name === 'situation'
            && pathInfo.meta?.title) ? t(pathInfo.meta.title) : t(initTitle);
    }, [JSON.stringify(location), JSON.stringify(routerArray)]);

    const renderPage = () => {
        const accessToken = getHashParamsString('access_token');
        if (accessToken) {
            return <Navigate to={`/forward_login?adfsToken=${accessToken}`} />;
        }
        if (location.pathname === '/forward_login' || location.pathname === '/invalid_page') {
            return children;
        }
        if (token && expireTime) {
            setToken(token);
            setExpireTime(expireTime);
            return <Navigate to="/" />;
        }
        if (!isAuth) {
            if (location.pathname === '/login') {
                return children;
            }
            return <Navigate to="/login" replace />;
        }
        if (!_.isEmpty(userInfo) && (!userInfo.groupId || _.isEmpty(userInfo.menu))) { // 角色无效
            return <Navigate to="/invalid_page" />;
        }
        if (_.isEmpty(routerArray)) {
            return <Loading size={80} containerStyle={{height: '100vh'}} />;
        }
        if (location.pathname === '/login') {
            return <Navigate to="/" />;
        }
        return children;
    };

    return renderPage();
};

AuthRouter.propTypes = {
    children: PropTypes.node
};
export default AuthRouter;
