import moment from 'moment';
import {publicConstant} from '@/utils/dictionaries';

export const TimeStyleEnum = {
    YMD: 'YYYY-MM-DD',
    YMDHM: 'YYYY-MM-DD HH:mm',
    YMDHMS: 'YYYY-MM-DD HH:mm:ss',
    YMDHMSS: 'YYYY-MM-DD HH:mm:ss.SSS',
    HM: 'HH:mm',
    HMS: 'HH:mm:ss',
    Y: 'YYYY',
    YM: 'YYYY-MM',
    MD: 'MM-DD'
};

export default {

    /**
     * 日期格式转换
     * @param {Date | number} timer - 时间戳
     * @param {string} formatType - 格式化类型
     * @returns {string} 'YYYY-MM-DD HH:mm:ss'格式，如果有其他格式要求，可自定义
     */
    transFormatDate(timer, formatType = TimeStyleEnum.YMDHMS) {
        if (!timer || timer === 'null') {
            return publicConstant.nullValue;
        }
        return moment(timer).format(formatType);
    },

    /**
     * 获取num HH:mm:ss
     * @param {number} num 向前几个单位
     * @param {string} type  eg:m-秒、h-时、d-天、w-周、M-月、Q-季、y-年
     * @returns {number[]}[num前 HH:mm:ss,当前时间 HH:mm:ss]
     */
    getSubtractTimePeriod(num, type) {
        const start = moment().subtract(num, type).valueOf();
        const end = moment().valueOf();
        return [start, end];
    },

    /**
     * 获取当前时间
     * @param {Date} date 时间
     * @returns {number} 时间戳
     */
    getCurrentTime(date = null) {
        if (date) {
            return moment(date).valueOf();
        }
        return moment().valueOf();
    },

    /**
     * 计算时间戳之差
     * @param {number} time 时间戳duration
     * @param {Function} t 翻译
     * @returns {string} 返回时间戳描述
     */
    getTimeDes(time, t) {
        const unit = key => {
            if (t) {
                return t(key);
            }
            return key;
        };

        const duration = moment.duration(time, 'ms');
        const days = duration.get('days');
        const hours = duration.get('hours');
        const mins = duration.get('minutes');
        const secs = duration.get('seconds');
        if (days > 0) {
            let str = days + unit('天');
            if (hours > 0) {
                str = str + hours + unit('小时');
            }
            return str;
        }
        else if (hours > 0) {
            return hours + unit('小时');
        }
        else if (mins > 0) {
            return mins + unit('分钟');
        }
        return secs + unit('秒');
    },

    /** 计算时间差-天
     * @param {number} time 时间戳之差
     * @returns {number} 时间差
     */
    getTimeAgoDayDes(time) {
        const duration = moment.duration(time, 'ms');
        const days = duration.get('days');
        if (days > 0) {
            return days;
        }
        return 0;
    },

    getTimeDiff(time, t) {
        const duration = moment.duration(time, 'ms');
        const days = duration.get('days');
        const hours = duration.get('hours');
        const mins = duration.get('minutes');
        const secs = duration.get('seconds');

        if (days > 0) {
            let str = days + t('天');
            if (hours > 0) {
                str = str + hours + t('小时');
                if (mins > 0) {
                    str = str + mins + t('分钟');
                    str = str + (secs || 0) + t('秒');
                }
            }
            return str;
        }
        else if (hours > 0) {
            let str = hours + t('小时');
            if (mins > 0) {
                str = str + mins + t('分钟');
                str = str + (secs || 0) + t('秒');
            }
            return str;
        }
        else if (mins > 0) {
            let str = mins + t('分钟');
            str = str + (secs || 0) + t('秒');
            return str;
        }
        return secs + t('秒');
    },

    /**
     * 获取周几
     * @param {number} timer 时间戳之差
     * @returns {string}
     */
    getWeek(timer) {
        const week = moment(timer).day();
        switch (week) {
            case 1:
                return '周一';
            case 2:
                return '周二';
            case 3:
                return '周三';
            case 4:
                return '周四';
            case 5:
                return '周五';
            case 6:
                return '周六';
            case 0:
                return '周日';
        }
    }
};
